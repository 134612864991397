import questionsData from "./QuestionData";
import QuestionTemplate from "../../templates/MBQuestionTemplate";

const Question17MB = () => {
  const questionData = questionsData.find((q) => q.id === "17");
  return (
    <QuestionTemplate
      question={questionData}
      answers={questionData.answers}
      field={questionData.field}
    />
  );
};

export default Question17MB;
