import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

import Layout from "../../components/layout/layout";
import Axis from "../../components/theme/axis";
import { Burgundy } from "../../components/theme/colors";
import { H3, H5, H6, TextSm } from "../../components/theme/text/Headings";
import { ButtonBrown } from "../../components/theme/Button";
import {
  BreakdownBody,
  BreakdownBottom,
  BreakdownContainer,
  BreakdownHeader,
  ResultsPageBox,
} from "../../components/theme/QuizBox";
import { SecPic } from "../Home";

import { auth, db } from "../../services/firebase.config";

const ProPic = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 3px solid ${Burgundy};
`;

const ResultsFD = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true); // Loading state
  const [currentUser, setCurrentUser] = useState(null); // Track current user
  const [username, setUsername] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [firstDateQuizId, setFirstDateQuizId] = useState("");

  const [firstDateQuizId2, setFirstDateQuizId2] = useState("");
  const [displayName2, setDisplayName2] = useState("");
  const [photoURL2, setPhotoURL2] = useState("");
  const [username2, setUsername2] = useState("");

  const [searchParams] = useSearchParams();
  const sharedId = searchParams.get("shared");

  // Listen for auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (!user) setLoading(false); // Stop loading if no user
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!currentUser) return;

      const profileDocRef = doc(db, "public-profiles", currentUser.uid);
      const profileDoc = await getDoc(profileDocRef);

      if (profileDoc.exists()) {
        const data = profileDoc.data();
        setUsername(data.username || "");
        setPhotoURL(data.photoURL || "");
        setDisplayName(data.displayName || "");

        setFirstDateQuizId(data.firstDateQuizId);
      }
    };

    fetchProfileData();
  }, [currentUser]);

  useEffect(() => {
    const getSharedCollection = async () => {
      if (!sharedId || !currentUser) {
        setLoading(false); // No shared ID or user, stop loading
        return;
      }

      const sharedDocRef = doc(db, "shared-quizzes", sharedId);
      const sharedDoc = await getDoc(sharedDocRef);

      if (sharedDoc.exists()) {
        const sharedData = sharedDoc.data();
        const otherUserUid =
          sharedData.senderUid === currentUser.uid
            ? sharedData.receiverUid
            : sharedData.senderUid;

        setFirstDateQuizId2(
          sharedData.senderUid === currentUser.uid
            ? sharedData.receiverQuizId
            : sharedData.senderQuizId
        );

        const profile2DocRef = doc(db, "public-profiles", otherUserUid);
        const profile2Doc = await getDoc(profile2DocRef);

        if (profile2Doc.exists()) {
          const data = profile2Doc.data();
          setUsername2(data.username || "");
          setPhotoURL2(data.photoURL || "");
          setDisplayName2(data.displayName || "");
        }
      }
      setLoading(false); // Loading done
    };

    getSharedCollection();
  }, [sharedId, currentUser, firstDateQuizId2]);

  if (loading) {
    // Show a loading spinner or placeholder
    return (
      <Layout>
        <Container>
          <Row className="mt-10 text-align-center">
            <Col>
              <H5>Loading your results...</H5>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container>
        <Row>
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          >
            <ResultsPageBox style={{ flexDirection: "column" }}>
              <TextSm>Quiz Results</TextSm>
              <H5 className="mb-20 mt-5-real">What's your perfect date?</H5>

              {!sharedId ? (
                <>
                  {photoURL ? (
                    <ProPic src={photoURL} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName ? displayName[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                </>
              ) : (
                <div className="display-flex">
                  {photoURL ? (
                    <ProPic src={photoURL} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName ? displayName[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                  <CompareArrowsIcon className="mt-15" />
                  {photoURL2 ? (
                    <ProPic src={photoURL2} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName2 ? displayName2[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                </div>
              )}
            </ResultsPageBox>
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          >
            <BreakdownContainer>
              <BreakdownHeader background={Burgundy}>
                <H6 className="mb-0">
                  Score Breakdown:{" "}
                  {username ? `@${username}` : `${displayName}`}{" "}
                  {sharedId && (
                    <> {username2 ? `+ @${username2}` : `+ ${displayName2}`}</>
                  )}
                </H6>
              </BreakdownHeader>
              <BreakdownBody>
                <Axis
                  quizName="firstDate"
                  quizId={firstDateQuizId}
                  senderQuizId={firstDateQuizId2}
                  leftLabel="Daytime"
                  rightLabel="Nighttime"
                  leftDescription="Prefers dates during the day."
                  rightDescription="Prefers dates during the night."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="night"
                />
                <Axis
                  quizName="firstDate"
                  quizId={firstDateQuizId}
                  senderQuizId={firstDateQuizId2}
                  leftLabel="Intimate"
                  rightLabel="Adventurous"
                  leftDescription="Prefers a one-on-one vibe."
                  rightDescription="Likes being amongst more people."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="adventurous"
                />
                <Axis
                  quizName="firstDate"
                  quizId={firstDateQuizId}
                  senderQuizId={firstDateQuizId2}
                  leftLabel="Spontaneous"
                  rightLabel="Planner"
                  leftDescription="Likes to go with the flow."
                  rightDescription="Prefers to plan the date."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="planner"
                />
                <Axis
                  quizName="firstDate"
                  quizId={firstDateQuizId}
                  senderQuizId={firstDateQuizId2}
                  leftLabel="Reserved"
                  rightLabel="Open"
                  leftDescription="Likes to take their time."
                  rightDescription="Most open to connection."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="open"
                />
                <Axis
                  quizName="firstDate"
                  quizId={firstDateQuizId}
                  senderQuizId={firstDateQuizId2}
                  leftLabel="Hard to get"
                  rightLabel="Simp"
                  leftDescription="More reserved, takes time to let someone in. "
                  rightDescription="Willing to do anything for affection"
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="simp"
                />
                <Axis
                  quizName="firstDate"
                  quizId={firstDateQuizId}
                  senderQuizId={firstDateQuizId2}
                  leftLabel="Thrifty"
                  rightLabel="Boujee"
                  leftDescription="Down to slum it."
                  rightDescription="Likes the finer things."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="boujee"
                />
              </BreakdownBody>
            </BreakdownContainer>
          </Col>
        </Row>
        {(currentUser?.isAnonymous || !currentUser) && (
          <Row>
            <Col
              lg={{ offset: 4, span: 4 }}
              md={{ offset: 3, span: 6 }}
              sm={{ offset: 2, span: 8 }}
              xs={{ span: 12 }}
              className="text-align-center"
            >
              <BreakdownBottom className="justify-content-center display-column">
                <H5 className="mb-20">👇 create an account 👇</H5>
                <ButtonBrown
                  className="mt-0"
                  width="80%"
                  onClick={() =>
                    navigate("/login", { state: { linkAnonymous: true } })
                  }
                >
                  save your answers
                </ButtonBrown>
              </BreakdownBottom>
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  );
};

export default ResultsFD;
