// questionsData.js
const questionsData = [
  {
    id: "1",
    nextQuestionId: "2",
    field: "feeling",
    questionText:
      "In a disagreement with a friend, do you focus on the facts or try to smooth things over to avoid tension?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "I’ll focus on the truth, but make sure we don’t kill the vibe.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "If I’m in the right I don’t back down.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I’m more interested in how we can grow from it.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "Is a fight worth risking our friendship? Let’s just get along.",
      },
    ],
  },
  {
    id: "2",
    nextQuestionId: "3",
    field: "perception",
    questionText:
      "Do you tend to finish tasks early or wait until the last minute?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "I finish tasks ahead of time to avoid any stress.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "I’m pulling a last minute all nighter every time.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I tend to wait until the deadline is near.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "I like to finish things in advance, but I’ll leave a few details to the last minute.",
      },
    ],
  },
  {
    id: "3",
    nextQuestionId: "4",
    field: "introversion",
    questionText:
      "In a group, do you tend to be the center of attention or stay more reserved?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "I’m more of a background character.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "I’ll happily take the spotlight when it’s my turn.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I’m in the corner with my bestie.",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "I’m the main character obviously!",
      },
    ],
  },
  {
    id: "4",
    nextQuestionId: "5",
    field: "perception",
    questionText:
      "When working on a project, do you like sticking to a set order or do you jump between tasks?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "Controlled chaos is my style.",
      },
      {
        answer: "2",
        percentage: 66,
        copy: "I might jump between tasks.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "I follow a clear order from start to finish.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "I stick to a general order, with minor changes.",
      },
    ],
  },
  {
    id: "5",
    nextQuestionId: "6",
    field: "introversion",
    questionText:
      "After a busy week, do you look forward to socializing or to relaxing quietly?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "Not feeling it, a quiet weekend sounds nice.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "If I have to talk to one more person, I’m going off grid.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "A small, laid-back hang sounds perfect.",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "I’m out the door before you finish that sentence.",
      },
    ],
  },
  {
    id: "6",
    nextQuestionId: "7",
    field: "intuition",
    questionText:
      "When reading, do you prefer stories that focus on realistic details or ones that explore abstract concepts and ideas?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "I need the details, make it feel real.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "Give me a plot that makes me question everything I thought I knew.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I like stories that make me think beyond the page.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "Give me a story with depth, but grounded.",
      },
    ],
  },
  {
    id: "7",
    nextQuestionId: "8",
    field: "perception",
    questionText:
      "Do you enjoy having structure, or do you prefer to go with the flow and see where things take you?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "I like having a loose plan, but I’m open to changes.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "Structure? Nah, I prefer to wing it and see what happens.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "Stick to the plan.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "I’m fine with letting things unfold as they come.",
      },
    ],
  },
  {
    id: "8",
    nextQuestionId: "9",
    field: "intuition",
    questionText:
      "In a work meeting, do you focus on the specifics or how things fits into larger trends or possibilities?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "I’m already thinking about how this fits into the grand scheme.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "I’ll consider the details, but open to larger concepts.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "Give me the details, I need clarity.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "Forget the details, I’m thinking bigger!",
      },
    ],
  },
  {
    id: "9",
    nextQuestionId: "10",
    field: "feeling",
    questionText:
      "If you had to fire someone, would you follow the rules strictly or consider their feelings in the situation?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Rules are rules—no exceptions.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "I’m considering their feelings first—rules can bend.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "I’ll follow the rules, but I won’t make it brutal.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "I’ll stick to the script, but I’ll be nice about it.",
      },
    ],
  },
  {
    id: "10",
    nextQuestionId: "11",
    field: "intuition",
    questionText:
      "When solving problems, do you lean towards practical solutions or explore creative alternatives?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "I’m brainstorming other angles.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "I’ll stick to what works, but open to suggestions.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "I go with precedent, no need to reinvent the wheel.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "Let’s throw out the rulebook and get creative!",
      },
    ],
  },
  {
    id: "11",
    nextQuestionId: "12",
    field: "feeling",
    questionText:
      "When choosing between job offers, do you focus on salary or how the role fits your values?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Show me the money, and I’ll decide.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "The salary matters most, but I’d like it to align with my passions.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "I’m chasing meaning, not just a paycheck.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "Values come first, but a good paycheck is a nice bonus.",
      },
    ],
  },
  {
    id: "12",
    nextQuestionId: "13",
    field: "introversion",
    questionText:
      "When you're feeling down, do you seek others for comfort or prefer solitude?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "I need my space to process.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "My bestie’s always on speed dial.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "I’ll reach out to a close friend, but I need some space too.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "I can handle it solo, but talking helps sometimes.",
      },
    ],
  },
  {
    id: "13",
    nextQuestionId: "14",
    field: "introversion",
    questionText:
      "Do you prefer spending your weekends with a group of friends or at home?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "Small group vibes are my sweet spot.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "Let’s grab the crew and make some questionable decisions!",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "I’d rather stay home, maybe catch up on some alone time.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "Friends are cool, but I’ll need a nap afterward.",
      },
    ],
  },
  {
    id: "14",
    nextQuestionId: "15",
    field: "perception",
    questionText:
      "When making plans with friends, do you prefer having a set routine or going with the flow?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "I’m all about spontaneous plans—let’s just see what happens.",
      },
      {
        answer: "2",
        percentage: 66,
        copy: "I’m flexible; we’ll figure it out when we get there.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "I like to have a basic plan, but I’m okay if we change things up.",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "I need everything scheduled.",
      },
    ],
  },
  {
    id: "15",
    nextQuestionId: "16",
    field: "feeling",
    questionText:
      "If a friend asks for advice, do you give practical solutions or aim to provide emotional support?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "I’ll be your shoulder to cry on.",
      },
      {
        answer: "2",
        percentage: 66,
        copy: "I’ll offer help but make sure you feel good.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "Here’s what to do, but how are you feeling?",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "Let’s get to the point and fix it",
      },
    ],
  },
  {
    id: "16",
    nextQuestionId: "17",
    field: "introversion",
    questionText:
      "After a long day, do you feel energized by socializing or by having quiet time at home?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "Might have a friend over, but I’m not going out.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "Home? Never heard of her.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "I’ll catch up with a few people.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "Catch me in my bed with ice cream and my favorite show.",
      },
    ],
  },
  {
    id: "17",
    field: "perception",
    questionText:
      "If plans change unexpectedly, do you find it exciting or frustrating?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "I’m all in—let’s see where the night takes us!",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "I’m side-eyeing any big changes.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "I’ll be annoyed if things don’t go as planned.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "I’ll be fine, but let’s not make this a habit.",
      },
    ],
  },
];

export default questionsData;
