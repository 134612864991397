import questionsData from "./QuestionData";
import QuestionTemplate from "../../templates/SSQuestionTemplate";

const Question18SS = () => {
  const questionData = questionsData.find((q) => q.id === "18");
  return (
    <QuestionTemplate
      question={questionData}
      answers={questionData.answers}
      field={questionData.field}
      nextQuestionId={questionData.nextQuestionId}
    />
  );
};

export default Question18SS;
