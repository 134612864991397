import styled from "styled-components";
import { Black07, DarkBrown, DarkBrown07, OffWhite, White } from "./colors";

export const Button = styled.button`
  background-color: ${(props) => props.background};
  color: ${OffWhite};
  padding: 10px 24px;
  font-family: "Ranille", sans-serif;
  font-weight: 800;
  margin-right: 10px;
  font-size: 15px;
  border-radius: 200px;
  border: none;
  width: ${(props) => props.width};
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.24);
  &:hover {
    background-color: ${Black07};
  }
`;

export const ButtonBrown = styled.button`
  background-color: ${DarkBrown};
  color: ${OffWhite};
  padding: 10px 24px;
  font-family: "Ranille", sans-serif;
  font-weight: 800;
  margin-right: 10px;
  font-size: 15px;
  border-radius: 200px;
  border: none;
  width: ${(props) => props.width};
  &:hover {
    background-color: ${DarkBrown07};
  }
`;

export const ButtonOutline = styled.button`
  padding: 10px 24px;
  font-family: "Ranille", sans-serif;
  font-weight: 800;
  margin-right: 10px;
  font-size: 15px;
  background-color: transparent;
  border: none;
  border-radius: 200px;
  color: ${(props) => props.color};
  color: ${(props) => props.textOverride};
  width: ${(props) => props.width};
  -webkit-box-shadow: inset 0px 0px 0px 2px ${(props) => props.color};
  -moz-box-shadow: inset 0px 0px 0px 2px ${(props) => props.color};
  box-shadow: inset 0px 0px 0px 2px ${(props) => props.color};
  &:hover {
    color: ${White};
    color: ${(props) => props.textOverride};
    background: ${(props) => props.color};
  }
`;

export const ButtonOutlineBrown = styled.button`
  padding: 10px 24px;
  font-family: "Ranille", sans-serif;
  font-weight: 800;
  margin-right: 10px;
  font-size: 15px;
  background-color: transparent;
  border: none;
  border-radius: 200px;
  color: ${DarkBrown};
  width: ${(props) => props.width};
  -webkit-box-shadow: inset 0px 0px 0px 2px ${DarkBrown};
  -moz-box-shadow: inset 0px 0px 0px 2px ${DarkBrown};
  box-shadow: inset 0px 0px 0px 2px ${DarkBrown};
  &:hover {
    color: ${White};
    background: ${DarkBrown07};
    -webkit-box-shadow: inset 0px 0px 0px 2px ${DarkBrown07};
    -moz-box-shadow: inset 0px 0px 0px 2px ${DarkBrown07};
    box-shadow: inset 0px 0px 0px 2px ${DarkBrown07};
  }
`;
