import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  linkWithPhoneNumber,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

import styled from "styled-components";
import { FilledInput, FormControl, InputLabel } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import logoImg from "../../images/logo/vBrown.png";

import { H4, TextSm } from "../../components/theme/text/Headings";
import { ButtonBrown } from "../../components/theme/Button";
import { Section } from "../../components/layout/layout";

import { auth } from "../../services/firebase.config";
import { Brown, DarkBrown, OffWhite } from "../../components/theme/colors";
import { useAuth } from "../../services/AuthContext";

const Body = styled.div`
  height: 100vh;
  position: relative;
  padding-bottom: 60px; /* Add space to ensure the content doesn't overlap with the button bar */
`;

const cleanAndValidatePhoneNumber = (number) => {
  // Remove all non-numeric characters
  const cleanedNumber = number.replace(/\D/g, "");

  // Add +1 if not present
  const formattedNumber =
    cleanedNumber.length === 10 ? `+1${cleanedNumber}` : `+${cleanedNumber}`;

  // Validate for a US number (should have 11 digits and start with +1)
  const isValidUSNumber = /^\+1\d{10}$/.test(formattedNumber);

  if (!isValidUSNumber) {
    throw new Error("Please enter a valid US phone number.");
  }

  return formattedNumber;
};

const Login = () => {
  const { currentUserState } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);

  const [nextStep, setNextStep] = useState(false);

  // Detects if this is a login to convert an anonymous account
  const isAnonymousLinking = location.state?.linkAnonymous;

  // Redirect if already logged in
  useEffect(() => {
    if (currentUserState) {
      navigate("/home");
    }
  }, [currentUserState, navigate]);

  const sendOtp = async () => {
    // Clean and validate the phone number
    const formattedPhoneNumber = cleanAndValidatePhoneNumber(phoneNumber);
    const appVerifier = window.recaptchaVerifier;

    try {
      const user = auth.currentUser;

      // If anonymous linking is required, use linkWithPhoneNumber
      if (isAnonymousLinking && user && user.isAnonymous) {
        const result = await linkWithPhoneNumber(
          user,
          formattedPhoneNumber,
          appVerifier
        );
        setConfirmationResult(result);
        setNextStep(true);
        console.log(
          "OTP sent to phone number for anonymous linking",
          formattedPhoneNumber
        );
      } else {
        // Regular phone sign-in for non-anonymous users
        const result = await signInWithPhoneNumber(
          auth,
          formattedPhoneNumber,
          appVerifier
        );
        setConfirmationResult(result);
        setNextStep(true);
        console.log(
          "OTP sent to phone number for regular sign-in",
          formattedPhoneNumber
        );
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const verifyOtp = async () => {
    try {
      const result = await confirmationResult.confirm(otp);
      const user = result.user;
      console.log("User signed in successfully:", user);
      navigate("/home");
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  useEffect(() => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
    }

    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log("reCAPTCHA solved", response);
          sendOtp();
        },
      }
    );
  });

  return (
    <div>
      <Body style={{ backgroundColor: OffWhite }}>
        <Section style={{ height: "90vh" }}>
          <Container>
            <Row style={{ paddingTop: "15vh" }}>
              <Col lg={{ offset: 4, span: 3 }}>
                <img
                  src={logoImg}
                  alt="Vibe Check Logo"
                  style={{ height: "40px" }}
                />
                <H4 className="mt-30" color={DarkBrown}>
                  Hi! Ready for a Vibe Check?
                </H4>
                <TextSm className="mt-10" color={Brown}>
                  Login or Create Account
                </TextSm>

                {!nextStep && (
                  <>
                    <FormControl variant="filled" className="mt-50" fullWidth>
                      <InputLabel htmlFor="phone">
                        enter your phone number
                      </InputLabel>
                      <FilledInput
                        id="phone"
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      {console.log("number", phoneNumber)}
                    </FormControl>

                    <ButtonBrown
                      width="100%"
                      className="mt-50"
                      onClick={sendOtp}
                    >
                      Text Me a Code
                    </ButtonBrown>
                  </>
                )}

                {nextStep && (
                  <>
                    <FormControl variant="filled" className="mt-50" fullWidth>
                      <InputLabel htmlFor="phone">enter your OTP</InputLabel>
                      <FilledInput
                        id="otp"
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </FormControl>

                    <ButtonBrown
                      width="100%"
                      className="mt-50"
                      onClick={verifyOtp}
                    >
                      login
                    </ButtonBrown>
                  </>
                )}
              </Col>
            </Row>
            <div id="recaptcha-container"></div>
          </Container>
        </Section>
      </Body>
    </div>
  );
};

export default Login;
