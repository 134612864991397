import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./components/theme/styling.css";
import { AuthProvider } from "./services/AuthContext";

import "react-multi-carousel/lib/styles.css";

import App from "./App";
import { LoadingProvider } from "./services/LoadingContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LoadingProvider>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </LoadingProvider>
);
