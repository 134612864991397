import React, { createContext, useState, useEffect, useContext } from "react";
import { auth } from "./firebase.config";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUserState, setCurrentUserState] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("AuthProvider: Setting up onAuthStateChanged...");
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log("AuthProvider: onAuthStateChanged fired with user:", user);
      setCurrentUserState(user || null);
      setLoading(false);
    });

    return unsubscribe; // Clean up the listener
  }, []);

  return (
    <AuthContext.Provider value={{ currentUserState, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
