import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useLoading } from "./LoadingContext";

function ProtectedRoute({ children }) {
  const { currentUserState, loading } = useAuth();
  const { setLoading } = useLoading();

  React.useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  if (loading) {
    return null;
  }

  if (!currentUserState) {
    return <Navigate to="/login" />;
  }

  return children;
}

export default ProtectedRoute;
