import React from "react";
import { Route, Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import ProtectedRoute from "./services/ProtectedRoute";

import Landing from "./pages/Landing";
import Login from "./pages/Login/Login";

import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Settings from "./pages/Settings";
import Results from "./pages/Results";

import LandingFD from "./pages/First-Date-Quiz/LandingFD";
import ResultsFD from "./pages/First-Date-Quiz/ResultsFD";
import QuestionOneFD from "./pages/First-Date-Quiz/QuestionFD1";
import QuestionTwoFD from "./pages/First-Date-Quiz/QuestionFD2";
import QuestionThreeFD from "./pages/First-Date-Quiz/QuestionFD3";
import QuestionFourFD from "./pages/First-Date-Quiz/QuestionFD4";
import QuestionFiveFD from "./pages/First-Date-Quiz/QuestionFD5";
import QuestionSixFD from "./pages/First-Date-Quiz/QuestionFD6";
import QuestionSevenFD from "./pages/First-Date-Quiz/QuestionFD7";
import QuestionEightFD from "./pages/First-Date-Quiz/QuestionFD8";
import QuestionNineFD from "./pages/First-Date-Quiz/QuestionFD9";
import QuestionTenFD from "./pages/First-Date-Quiz/QuestionFD10";
import QuestionElevenFD from "./pages/First-Date-Quiz/QuestionFD11";
import Question12FD from "./pages/First-Date-Quiz/QuestionFD12";
import Question13FD from "./pages/First-Date-Quiz/QuestionFD13";
import Question14FD from "./pages/First-Date-Quiz/QuestionFD14";
import Question15FD from "./pages/First-Date-Quiz/QuestionFD15";
import QuestionFD16 from "./pages/First-Date-Quiz/QuestionFD16";
import Question17FD from "./pages/First-Date-Quiz/QuestionFD17";
import Question18FD from "./pages/First-Date-Quiz/QuestionFD18";
import Question19FD from "./pages/First-Date-Quiz/QuestionFD19";

import LandingPA from "./pages/Political-Alignment-Quiz/LandingPA";
import ResultsPA from "./pages/Political-Alignment-Quiz/ResultsPA";
import QuestionPA1 from "./pages/Political-Alignment-Quiz/QuestionPA1";
import QuestionPA2 from "./pages/Political-Alignment-Quiz/QuestionPA2";
import QuestionPA3 from "./pages/Political-Alignment-Quiz/QuestionPA3";
import QuestionPA4 from "./pages/Political-Alignment-Quiz/QuestionPA4";
import QuestionPA5 from "./pages/Political-Alignment-Quiz/QuestionPA5";
import QuestionPA6 from "./pages/Political-Alignment-Quiz/QuestionPA6";
import QuestionPA7 from "./pages/Political-Alignment-Quiz/QuestionPA7";
import QuestionPA8 from "./pages/Political-Alignment-Quiz/QuestionPA8";
import QuestionPA9 from "./pages/Political-Alignment-Quiz/QuestionPA9";
import QuestionPA10 from "./pages/Political-Alignment-Quiz/QuestionPA10";
import QuestionPA11 from "./pages/Political-Alignment-Quiz/QuestionPA11";
import QuestionPA12 from "./pages/Political-Alignment-Quiz/QuestionPA12";
import QuestionPA13 from "./pages/Political-Alignment-Quiz/QuestionPA13";
import QuestionPA14 from "./pages/Political-Alignment-Quiz/QuestionPA14";
import QuestionPA15 from "./pages/Political-Alignment-Quiz/QuestionPA15";
import QuestionPA16 from "./pages/Political-Alignment-Quiz/QuestionPA16";
import QuestionPA17 from "./pages/Political-Alignment-Quiz/QuestionPA17";
import QuestionPA18 from "./pages/Political-Alignment-Quiz/QuestionPA18";

import LandingMB from "./pages/Myers-Briggs-Quiz/LandingMB";
import ResultsMB from "./pages/Myers-Briggs-Quiz/ResultsMB";
import QuestionOneMB from "./pages/Myers-Briggs-Quiz/QuestionMB1";
import QuestionTWoMB from "./pages/Myers-Briggs-Quiz/QuestionMB2";
import QuestionThreeMB from "./pages/Myers-Briggs-Quiz/QuestionMB3";
import QuestionFourMB from "./pages/Myers-Briggs-Quiz/QuestionMB4";
import QuestionFiveMB from "./pages/Myers-Briggs-Quiz/QuestionMB5";
import QuestionSixMB from "./pages/Myers-Briggs-Quiz/QuestionMB6";
import QuestionSevenMB from "./pages/Myers-Briggs-Quiz/QuestionMB7";
import QuestionEightMB from "./pages/Myers-Briggs-Quiz/QuestionMB8";
import QuestionNineMB from "./pages/Myers-Briggs-Quiz/QuestionMB9";
import QuestionTenMB from "./pages/Myers-Briggs-Quiz/QuestionMB10";
import QuestionElevenMB from "./pages/Myers-Briggs-Quiz/QuestionMB11";
import QuestionTwelveMB from "./pages/Myers-Briggs-Quiz/QuestionMB12";
import Question13MB from "./pages/Myers-Briggs-Quiz/QuestionMB13";
import Question14MB from "./pages/Myers-Briggs-Quiz/QuestionMB14";
import Question15MB from "./pages/Myers-Briggs-Quiz/QuestionMB15";
import Question16MB from "./pages/Myers-Briggs-Quiz/QuestionMB16";
import Question17MB from "./pages/Myers-Briggs-Quiz/QuestionMB17";

import LandingSS from "./pages/Sex-Style-Quiz/LandingSexStyle";
import ResultsSS from "./pages/Sex-Style-Quiz/ResultsSS";
import QuestionOneSS from "./pages/Sex-Style-Quiz/QuestionSS1";
import QuestionTwoSS from "./pages/Sex-Style-Quiz/QuestionSS2";
import QuestionThreeSS from "./pages/Sex-Style-Quiz/QuestionSS3";
import QuestionFourSS from "./pages/Sex-Style-Quiz/QuestionSS4";
import QuestionFiveSS from "./pages/Sex-Style-Quiz/QuestionSS5";
import QuestionSixSS from "./pages/Sex-Style-Quiz/QuestionSS6";
import QuestionSevenSS from "./pages/Sex-Style-Quiz/QuestionSS7";
import QuestionEightSS from "./pages/Sex-Style-Quiz/QuestionSS8";
import QuestionSSNine from "./pages/Sex-Style-Quiz/QuestionSS9";
import QuestionTenSS from "./pages/Sex-Style-Quiz/QuestionSS10";
import Question11SS from "./pages/Sex-Style-Quiz/QuestionSS11";
import Question12SS from "./pages/Sex-Style-Quiz/QuestionSS12";
import Question13SS from "./pages/Sex-Style-Quiz/QuestionSS13";
import Question14SS from "./pages/Sex-Style-Quiz/QuestionSS14";
import Question15SS from "./pages/Sex-Style-Quiz/QuestionSS15";
import QuestionSS16 from "./pages/Sex-Style-Quiz/QuestionSS16";
import Question17SS from "./pages/Sex-Style-Quiz/QuestionSS17";
import Question18SS from "./pages/Sex-Style-Quiz/QuestionSS18";
import Question19SS from "./pages/Sex-Style-Quiz/QuestionSS19";
import Question20SS from "./pages/Sex-Style-Quiz/QuestionSS20";
import Question21SS from "./pages/Sex-Style-Quiz/QuestionSS21";
import Question22SS from "./pages/Sex-Style-Quiz/QuestionSS22";
import Question23SS from "./pages/Sex-Style-Quiz/QuestionSS23";
import Question24SS from "./pages/Sex-Style-Quiz/QuestionSS24";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />

      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/results"
        element={
          <ProtectedRoute>
            <Results />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />

      <Route path="/political-alignment" element={<LandingPA />} />
      <Route path="/political-alignment/results" element={<ResultsPA />} />
      <Route path="/political-alignment-question-1" element={<QuestionPA1 />} />
      <Route path="/political-alignment-question-2" element={<QuestionPA2 />} />
      <Route path="/political-alignment-question-3" element={<QuestionPA3 />} />
      <Route path="/political-alignment-question-4" element={<QuestionPA4 />} />
      <Route path="/political-alignment-question-5" element={<QuestionPA5 />} />
      <Route path="/political-alignment-question-6" element={<QuestionPA6 />} />
      <Route path="/political-alignment-question-7" element={<QuestionPA7 />} />
      <Route path="/political-alignment-question-8" element={<QuestionPA8 />} />
      <Route path="/political-alignment-question-9" element={<QuestionPA9 />} />
      <Route
        path="/political-alignment-question-10"
        element={<QuestionPA10 />}
      />
      <Route
        path="/political-alignment-question-11"
        element={<QuestionPA11 />}
      />
      <Route
        path="/political-alignment-question-12"
        element={<QuestionPA12 />}
      />
      <Route
        path="/political-alignment-question-13"
        element={<QuestionPA13 />}
      />
      <Route
        path="/political-alignment-question-14"
        element={<QuestionPA14 />}
      />
      <Route
        path="/political-alignment-question-15"
        element={<QuestionPA15 />}
      />
      <Route
        path="/political-alignment-question-16"
        element={<QuestionPA16 />}
      />
      <Route
        path="/political-alignment-question-17"
        element={<QuestionPA17 />}
      />
      <Route
        path="/political-alignment-question-18"
        element={<QuestionPA18 />}
      />

      <Route path="/best-date" element={<LandingFD />} />
      <Route path="/best-date/results" element={<ResultsFD />} />
      <Route path="/best-date-question-1" element={<QuestionOneFD />} />
      <Route path="/best-date-question-2" element={<QuestionTwoFD />} />
      <Route path="/best-date-question-3" element={<QuestionThreeFD />} />
      <Route path="/best-date-question-4" element={<QuestionFourFD />} />
      <Route path="/best-date-question-5" element={<QuestionFiveFD />} />
      <Route path="/best-date-question-6" element={<QuestionSixFD />} />
      <Route path="/best-date-question-7" element={<QuestionSevenFD />} />
      <Route path="/best-date-question-8" element={<QuestionEightFD />} />
      <Route path="/best-date-question-9" element={<QuestionNineFD />} />
      <Route path="/best-date-question-10" element={<QuestionTenFD />} />
      <Route path="/best-date-question-11" element={<QuestionElevenFD />} />
      <Route path="/best-date-question-12" element={<Question12FD />} />
      <Route path="/best-date-question-13" element={<Question13FD />} />
      <Route path="/best-date-question-14" element={<Question14FD />} />
      <Route path="/best-date-question-15" element={<Question15FD />} />
      <Route path="/best-date-question-16" element={<QuestionFD16 />} />
      <Route path="/best-date-question-17" element={<Question17FD />} />
      <Route path="/best-date-question-18" element={<Question18FD />} />
      <Route path="/best-date-question-19" element={<Question19FD />} />

      <Route path="/myers-briggs" element={<LandingMB />} />
      <Route path="/myers-briggs/results" element={<ResultsMB />} />
      <Route path="/myers-briggs-question-1" element={<QuestionOneMB />} />
      <Route path="/myers-briggs-question-2" element={<QuestionTWoMB />} />
      <Route path="/myers-briggs-question-3" element={<QuestionThreeMB />} />
      <Route path="/myers-briggs-question-4" element={<QuestionFourMB />} />
      <Route path="/myers-briggs-question-5" element={<QuestionFiveMB />} />
      <Route path="/myers-briggs-question-6" element={<QuestionSixMB />} />
      <Route path="/myers-briggs-question-7" element={<QuestionSevenMB />} />
      <Route path="/myers-briggs-question-8" element={<QuestionEightMB />} />
      <Route path="/myers-briggs-question-9" element={<QuestionNineMB />} />
      <Route path="/myers-briggs-question-10" element={<QuestionTenMB />} />
      <Route path="/myers-briggs-question-11" element={<QuestionElevenMB />} />
      <Route path="/myers-briggs-question-12" element={<QuestionTwelveMB />} />
      <Route path="/myers-briggs-question-13" element={<Question13MB />} />
      <Route path="/myers-briggs-question-14" element={<Question14MB />} />
      <Route path="/myers-briggs-question-15" element={<Question15MB />} />
      <Route path="/myers-briggs-question-16" element={<Question16MB />} />
      <Route path="/myers-briggs-question-17" element={<Question17MB />} />

      <Route path="/sex-styles" element={<LandingSS />} />
      <Route path="/sex-styles/results" element={<ResultsSS />} />
      <Route path="/sex-styles-question-1" element={<QuestionOneSS />} />
      <Route path="/sex-styles-question-2" element={<QuestionTwoSS />} />
      <Route path="/sex-styles-question-3" element={<QuestionThreeSS />} />
      <Route path="/sex-styles-question-4" element={<QuestionFourSS />} />
      <Route path="/sex-styles-question-5" element={<QuestionFiveSS />} />
      <Route path="/sex-styles-question-6" element={<QuestionSixSS />} />
      <Route path="/sex-styles-question-7" element={<QuestionSevenSS />} />
      <Route path="/sex-styles-question-8" element={<QuestionEightSS />} />
      <Route path="/sex-styles-question-9" element={<QuestionSSNine />} />
      <Route path="/sex-styles-question-10" element={<QuestionTenSS />} />
      <Route path="/sex-styles-question-11" element={<Question11SS />} />
      <Route path="/sex-styles-question-12" element={<Question12SS />} />
      <Route path="/sex-styles-question-13" element={<Question13SS />} />
      <Route path="/sex-styles-question-14" element={<Question14SS />} />
      <Route path="/sex-styles-question-15" element={<Question15SS />} />
      <Route path="/sex-styles-question-16" element={<QuestionSS16 />} />
      <Route path="/sex-styles-question-17" element={<Question17SS />} />
      <Route path="/sex-styles-question-18" element={<Question18SS />} />
      <Route path="/sex-styles-question-19" element={<Question19SS />} />
      <Route path="/sex-styles-question-20" element={<Question20SS />} />
      <Route path="/sex-styles-question-21" element={<Question21SS />} />
      <Route path="/sex-styles-question-22" element={<Question22SS />} />
      <Route path="/sex-styles-question-23" element={<Question23SS />} />
      <Route path="/sex-styles-question-24" element={<Question24SS />} />
    </Routes>
  );
}
