import React, { createContext, useState, useContext } from "react";

import styled from "styled-components";
import { ClimbingBoxLoader } from "react-spinners";

import { StyledLayout, Wrapper } from "../components/layout/layout";
import { DarkBrown, OffWhite } from "../components/theme/colors";

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
`;

const CenteredLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false); // Global loading state

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {loading && ( // Show the global loader if `loading` is true
        <Wrapper background={OffWhite}>
          <StyledLayout>
            <Body>
              <CenteredLoaderContainer>
                <ClimbingBoxLoader color={DarkBrown} />
              </CenteredLoaderContainer>
            </Body>
          </StyledLayout>
        </Wrapper>
      )}
      {children}
    </LoadingContext.Provider>
  );
};

// Custom Hook to use the LoadingContext
export const useLoading = () => useContext(LoadingContext);
