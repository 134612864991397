import React from "react";
import styled from "styled-components";
import { Paper } from "@mui/material";

import LabelBottomNavigation from "./BottomNavigation";
import NavbarComp from "./NavbarComp";
import { auth } from "../../services/firebase.config";
import { OffWhite } from "../theme/colors";

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.background};
`;

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Body = styled.div`
  margin-bottom: 100px;
  overflow-x: hidden;
`;

export const Section = styled.div`
  min-height: 25vh;
  padding: 50px 10px 50px;
`;

export const StickyFooter = styled(Paper)`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10; /* Ensure it stays above other elements */
`;

function Layout(props) {
  const currentUser = auth.currentUser;
  return (
    <Wrapper background={props.background || OffWhite}>
      <NavbarComp />
      <StyledLayout>
        <Body>{props.children}</Body>
      </StyledLayout>
      {currentUser && !currentUser.isAnonymous && (
        <StickyFooter
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <LabelBottomNavigation />
        </StickyFooter>
      )}
    </Wrapper>
  );
}

export default Layout;
