import React from "react";
import { Navbar } from "react-bootstrap";
import styled from "styled-components";

import logoImg from "../../images/logo/vBrown.png";

const NavbarCont = styled(Navbar)`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Lines stay at the top */
  position: relative; /* Allows absolute positioning for the logo */
  padding-top: 0;
  padding-bottom: 0;
`;

const CenteredLogo = styled.div`
  position: absolute; /* Position relative to NavbarCont */
  top: 50%; /* Center vertically within NavbarCont */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Correct positioning */
  z-index: 1; /* Ensure the logo is in front of the lines */
`;

function NavbarComp() {
  return (
    <NavbarCont>
      <CenteredLogo>
        <img src={logoImg} alt="Vibe Check Logo" style={{ height: "30px" }} />
      </CenteredLogo>
    </NavbarCont>
  );
}

export default NavbarComp;
