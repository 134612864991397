// questionsData.js
const questionsData = [
  {
    id: "1",
    nextQuestionId: "2",
    field: "night",
    questionText: "Which date is more your style?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "Nighttime drinks! Let's explore the town.",
      },
      {
        answer: "2",
        percentage: 66,
        copy: "An afternoon activity like an arcade or museum.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "A picnic or lunch on a nice day.",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "Early coffee in a cute cafe.",
      },
    ],
  },
  {
    id: "2",
    nextQuestionId: "3",
    field: "adventurous",
    questionText: "Do you prefer a quiet or lively setting for a first date?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "Somewhere with a buzz.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "Mostly low-key, with a little energy.",
      },
      { answer: "3", percentage: 0, copy: "Chill and quiet, please." },
      { answer: "4", percentage: 100, copy: "The livelier, the better!" },
    ],
  },
  {
    id: "3",
    nextQuestionId: "4",
    field: "planner",
    questionText:
      "When picking a date spot, would you rather check out reviews or dive into a place and see how it goes?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "I'll check a couple of reviews to make sure it's worth a shot.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "I always check Yelp before trying a new place.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "Skip the reviews—half the fun is the surprise!",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "I'll glance at the star rating, but I'm not digging too deep.",
      },
    ],
  },
  {
    id: "4",
    nextQuestionId: "5",
    field: "open",
    questionText:
      "If the vibe is right on the first date, would you take things beyond just a hug goodbye?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "Perfect date? I'd invite them over.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "Maybe a quick kiss, but nothing big.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "If the vibe's there, we're making out.",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "Just a hug, it's only the first date.",
      },
    ],
  },
  {
    id: "5",
    nextQuestionId: "6",
    field: "boujee",
    questionText: "What's your go-to cocktail?",
    answers: [
      { answer: "1", percentage: 100, copy: "Espresso Martini" },
      {
        answer: "2",
        percentage: 66,
        copy: "Aperol Spritz",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "Margarita",
      },
      { answer: "4", percentage: 0, copy: "Jack & Coke" },
    ],
  },
  {
    id: "6",
    nextQuestionId: "7",
    field: "planner",
    questionText:
      "Would you rather have a set plan for the date or go with the flow?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "I’ll roll with it, but I’ll need a hint about what we’re doing.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "Plans? Never heard of them.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "I need a set plan to feel comfortable.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "I like a flexible plan that can change as we go.",
      },
    ],
  },
  {
    id: "7",
    nextQuestionId: "8",
    field: "adventurous",
    questionText: "If I took you out to dinner, where would you like to go?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "A trendy restaurant with a tasting menu.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "A quiet, tucked-away place where we can really talk.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "A cozy wine bar with dim lighting and a chill vibe.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "Let's go all out—a local spot with live music or a fun atmosphere.",
      },
    ],
  },
  {
    id: "8",
    nextQuestionId: "9",
    field: "boujee",
    questionText: "What's your favorite kind of bar?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "A cozy pub with games or music.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "An upscale lounge with craft cocktails.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "A stylish cocktail bar with unique drinks.",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "I love a fun dive bar.",
      },
    ],
  },
  {
    id: "9",
    nextQuestionId: "10",
    field: "simp",
    questionText: "How do you usually show someone you're interested in them?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "I keep my feelings to myself until I'm sure.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "I'll drop a hint or two, but I'm cautious.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "I'm all in—expressing interest is no problem, I'm open about it.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "I make an effort to show I care, but I take my time.",
      },
    ],
  },
  {
    id: "10",
    nextQuestionId: "11",
    field: "open",
    questionText: "Would you take a shot with me on the first date?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "You could try to convince me...",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "I'd rather wait—let's keep it chill for now.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "If we vibe... sure!",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "I'm game! Let's start strong.",
      },
    ],
  },
  {
    id: "11",
    nextQuestionId: "12",
    field: "open",
    questionText:
      "Are you quick to fall in love or prefer a gradual connection?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Take things slow—no rush.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "Love at first sight all the way!",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I like when things heat up quickly.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "Mostly slow, but a bit of chemistry helps.",
      },
    ],
  },
  {
    id: "12",
    nextQuestionId: "13",
    field: "simp",
    questionText:
      "If your date asks you something personal, how would you respond?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "I'd jump right in and reciprocate the interest!",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "I'd change the subject to something lighter.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I'd share a little bit, and open up more if it feels safe.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "I wouldn’t feel comfortable sharing, so maybe the bare minimum?",
      },
    ],
  },
  {
    id: "13",
    nextQuestionId: "14",
    field: "open",
    questionText:
      "Would someone sharing too much personal info turn you off on a first date?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "I'd be okay with a bit of real talk.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "A little—it's a bit much for a first date.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "Absolutely, I'd be so uncomfortable.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "I wouldn't mind, I love deep convos!",
      },
    ],
  },
  {
    id: "14",
    nextQuestionId: "15",
    field: "planner",
    questionText:
      "How do you feel about trying an activity neither of us has done before—exciting or too risky?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Absolutely! I love the thrill of trying something new.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "I'd be up for it—an unexpected adventure sounds fun!",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "Maybe, but I'd want to know a little about what to expect.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "I'd rather stick with something familiar and planned.",
      },
    ],
  },
  {
    id: "15",
    nextQuestionId: "16",
    field: "open",
    questionText:
      "What's your approach to being vulnerable with someone on a date?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "I stay guarded until I’m sure about them.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "I'm all in—honest and unfiltered from the start.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "I keep things light and save personal stuff for later.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "I'm open about who I am but keep it casual.",
      },
    ],
  },
  {
    id: "16",
    nextQuestionId: "17",
    field: "planner",
    questionText:
      "Would you rather your date plan the evening or take charge yourself?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "I love planning dates — let me handle it!",
      },
      {
        answer: "2",
        percentage: 66,
        copy: "I'd like to do some of the planning myself.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "I prefer a partner who takes the lead, mostly.",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "I love when someone else plans it all.",
      },
    ],
  },
  {
    id: "17",
    nextQuestionId: "18",
    field: "adventurous",
    questionText: "Is a crowded event on a first date exciting or too much?",
    answers: [
      { answer: "1", percentage: 0, copy: "Way too much—no thanks!" },
      {
        answer: "2",
        percentage: 33,
        copy: "Small groups are fine, but I’d rather keep it low-key.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I'm open to it, sounds interesting.",
      },
      { answer: "4", percentage: 100, copy: "Love it—more people, more fun!" },
    ],
  },
  {
    id: "18",
    nextQuestionId: "19",
    field: "simp",
    questionText:
      "If your date gives you a compliment, would you downplay it or return it with enthusiasm?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "I’d probably just say ‘thanks’ and move on.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "I’d brush it off and make a joke to deflect.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I’d accept it with a smile and casually return the compliment.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "I’d thank them and give them a thoughtful compliment back.",
      },
    ],
  },
  {
    id: "19",
    field: "boujee",
    questionText: "What's the one dish you could never get tired of?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "Bulgogi",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "Sushi",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "Surf & Turf",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "Smash Burger",
      },
    ],
  },
];

export default questionsData;
