// questionsData.js
const questionsData = [
  {
    id: "1",
    nextQuestionId: "2",
    field: "hardcore",
    questionText: "Do you enjoy mild choking or spanking in the bedroom?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "I’m fine with a tease, just don’t overdo it.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "Let’s keep it gentle.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I’m down but don’t leave marks.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "Mild isn’t enough…",
      },
    ],
  },
  {
    id: "2",
    nextQuestionId: "3",
    field: "rough",
    questionText:
      "How important is it for you to have moments of deep intimacy, such as slow kissing and touching, versus more physical action?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Hold me close and don’t let go.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "Skip the sweet stuff—let’s get to it.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "I love a good cuddle session.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "Let’s mix it up, soft and rough.",
      },
    ],
  },
  {
    id: "3",
    nextQuestionId: "4",
    field: "casual",
    questionText:
      "Do you find it more satisfying when sex is intertwined with care?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "It’s nice, but I don’t need it.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "Ew that gives me the ick.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "Not really, only if my partner wants it.",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "Yes, show me you NEED me.",
      },
    ],
  },
  {
    id: "4",
    nextQuestionId: "5",
    field: "dom",
    questionText:
      "How do you feel about receiving direction or orders during sex?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "I’ll do whatever you ask…",
      },
      {
        answer: "2",
        percentage: 66,
        copy: "Tell me what to do and I’ll decide if I want to.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "I don’t take orders, I give them.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "If you ask nicely, I’ll obey.",
      },
    ],
  },
  {
    id: "5",
    nextQuestionId: "6",
    field: "exhibition",
    questionText:
      "How much thrill do you get from the idea of others catching a glimpse of you in an intimate or revealing moment?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "I love the attention.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "It’s kind of embarrassing.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "I’d want to disappear immediately.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "It’s low-key exciting.",
      },
    ],
  },
  {
    id: "6",
    nextQuestionId: "7",
    field: "voyeur",
    questionText:
      "Imagine being in a private room with a one-way mirror: how appealing is the idea of watching others from the hidden side?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "I could enjoy it, especially if it’s secretive.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "Not at all—I’d rather not watch anyone.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "Grab the popcorn, I’m in!",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "It’s interesting, but I’m not sure I’d enjoy it much.",
      },
    ],
  },
  {
    id: "7",
    nextQuestionId: "8",
    field: "hardcore",
    questionText:
      "How comfortable are you with physical intensity, like spanking or rough handling, during intimacy?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "A little roughness is okay, but only in moderation.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "I enjoy some intensity, but I’m more into the gentle side of things.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "I like things gentle—I’m not into physical intensity.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "I love when it’s intense—no holding back.",
      },
    ],
  },
  {
    id: "8",
    nextQuestionId: "9",
    field: "dom",
    questionText:
      "Do you enjoy the idea of pleasing your partner by surrendering control, or do you prefer to dictate the sexual activities?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Do whatever you want to me baby.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "How can I please you?",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "Let me show you what I want.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "Shut up and lie down.",
      },
    ],
  },
  {
    id: "9",
    nextQuestionId: "10",
    field: "rough",
    questionText:
      "How much do you enjoy incorporating physical power play, such as spanking or hair-pulling, into your sexual encounters?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "I’m open to it if the mood is right.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "That’s a hard no for me.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "Make it rough enough to remember.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "Maybe just a little tap.",
      },
    ],
  },
  {
    id: "10",
    nextQuestionId: "11",
    field: "dom",
    questionText:
      "How comfortable are you with the idea of being 'punished' in a consensual, playful manner during sex?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Slap me! I love playful punishment.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "I don’t like being punished at all.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "I’m open to punishment if it feels right in the moment.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "Only if the other person asks for it.",
      },
    ],
  },
  {
    id: "11",
    nextQuestionId: "12",
    field: "exhibition",
    questionText:
      "How comfortable are you with the thought of performing sexual acts in front of others?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "It’s not my thing, but maybe if I’m drunk.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "No way—I don’t even want my pet in the room.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I could be open to it.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "I love to perform in front of an audience.",
      },
    ],
  },
  {
    id: "12",
    nextQuestionId: "13",
    field: "voyeur",
    questionText: "Does the thought of watching a couple go at it excite you?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Not at all—I’d rather not see that.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "I’d stay until the credits roll and then ask for a sequel.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I’d stick around for a bit.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "Eh I’d feel weird about it.",
      },
    ],
  },
  {
    id: "13",
    nextQuestionId: "14",
    field: "casual",
    questionText:
      "What’s your attitude towards sex that’s purely physical, with no desire for deeper emotional involvement?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "I could do the occasional hookup.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "I’m not comfortable with it—emotional connection is essential.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "My roster would say I don’t care.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "I prefer emotional involvement.",
      },
    ],
  },
  {
    id: "14",
    nextQuestionId: "15",
    field: "exhibition",
    questionText:
      "How thrilling do you find the idea of being intimate in a public or semi-public setting?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "I love being risky.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "Maybe in a super hidden spot.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I’ll do it in the car, but nothing more risky than that.",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "Not my thing—privacy is a must.",
      },
    ],
  },
  {
    id: "15",
    nextQuestionId: "16",
    field: "hardcore",
    questionText:
      "How do you feel about incorporating physical dominance or restraint, such as holding wrists or bondage?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Let’s keep it vanilla.",
      },
      {
        answer: "2",
        percentage: 66,
        copy: "I'm down, get the cuffs out.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "You can hold my wrists, but don't tie me up.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "Come see my red room.",
      },
    ],
  },
  {
    id: "16",
    nextQuestionId: "17",
    field: "dom",
    questionText:
      "Do you enjoy the idea of being responsible for your partner's pleasure in a dominant or controlling way?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "I’ll have my way with you.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "I’m more of a team player than a leader.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I can take charge if the moment calls for it.",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "No, I want you to show me what you like.",
      },
    ],
  },
  {
    id: "17",
    nextQuestionId: "18",
    field: "casual",
    questionText:
      "Are you comfortable with having sex without any emotional attachment?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "Emotions aren’t a priority.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "I need an emotional connection.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "I don’t want to know anything about you.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "Maybe I wouldn’t care if I’m drunk.",
      },
    ],
  },
  {
    id: "18",
    nextQuestionId: "19",
    field: "hardcore",
    questionText:
      "How open are you to using tools such as cuffs, whips, or ropes during intimacy?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Not my thing—I prefer intimacy without props.",
      },
      {
        answer: "2",
        percentage: 66,
        copy: "I’m into exploring deeper.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "Ropes and whips are part of what I enjoy most.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "I’d try something light like cuffs, but that’s about it.",
      },
    ],
  },
  {
    id: "19",
    nextQuestionId: "20",
    field: "casual",
    questionText: "How much do you enjoy pillow talking after sex?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "With the right person it can be fun.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "I love those moments.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "If you want me to, but don’t expect me to stay the night.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "Once I finish I’m over it…",
      },
    ],
  },
  {
    id: "20",
    nextQuestionId: "21",
    field: "voyeur",
    questionText:
      "Imagine walking into a room and catching a glimpse of two people hooking up—how do you feel about it?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "I couldn’t look away!",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "Turn around and walk out the door.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I might watch.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "Awkward and uncomfortable.",
      },
    ],
  },
  {
    id: "21",
    nextQuestionId: "22",
    field: "rough",
    questionText:
      "Are you more drawn to the power and energy of rough sex or the tenderness of passionate, slow sex?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "I only prefer slow sex.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "I don't like rough, but if my partner wants it.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "Let it build until it’s anything but gentle.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "I don’t do soft and sweet.",
      },
    ],
  },
  {
    id: "22",
    nextQuestionId: "23",
    field: "dom",
    questionText:
      "Do you prefer a partner who naturally takes control or wants you to guide the experience?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Take the reins, I’m all yours.",
      },
      {
        answer: "2",
        percentage: 66,
        copy: "I like to guide, but sharing control is fine.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "I’ll step up if needed.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "Surrender control to me.",
      },
    ],
  },
  {
    id: "23",
    nextQuestionId: "24",
    field: "exhibition",
    questionText:
      "How much do you like the idea of dressing provocatively or in ways that might draw attention to your sexuality in public?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "I love feeling sexy and getting attention.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "I’m not comfortable with that kind of attention.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "I like it occasionally.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "I have to be in the mood for it..",
      },
    ],
  },
  {
    id: "24",
    field: "rough",
    questionText:
      "When it comes to intimacy, do you feel more connected during slow, tender moments, or do you prefer fast-paced and intense sex?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Slow and steady wins my heart.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "Take your time—I’m all about the build-up.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "Give me a mix of passion and speed.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "Faster, harder, let’s go!",
      },
    ],
  },
];

export default questionsData;
