import questionsData from "./QuestionData";
import QuestionTemplate from "../../templates/SSQuestionTemplate";

const Question24SS = () => {
  const questionData = questionsData.find((q) => q.id === "24");
  return (
    <QuestionTemplate
      question={questionData}
      answers={questionData.answers}
      field={questionData.field}
    />
  );
};

export default Question24SS;
