import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

import Layout from "../../components/layout/layout";
import {
  H3,
  H5,
  H6,
  TextSm,
  TextXs,
} from "../../components/theme/text/Headings";
import {
  BlurredBackground,
  BlurredOverlay,
  BreakdownBody,
  BreakdownBottom,
  BreakdownContainer,
  BreakdownHeader,
  ResultsPageBox,
  UpgradeBoxLocked,
  UpgradeContainer,
  UpgradeHeader,
} from "../../components/theme/QuizBox";
import Axis from "../../components/theme/axis";
import { Orange } from "../../components/theme/colors";
import { ButtonBrown } from "../../components/theme/Button";
import { SecPic } from "../Home";

import { auth, db } from "../../services/firebase.config";
import { Lock } from "@mui/icons-material";

const ProPic = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 3px solid ${Orange};
`;

const ResultsPA = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true); // Loading state
  const [currentUser, setCurrentUser] = useState(null); // Track current user
  const [username, setUsername] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [quizId, setQuizId] = useState("");

  const [quizId2, setQuizId2] = useState("");
  const [displayName2, setDisplayName2] = useState("");
  const [photoURL2, setPhotoURL2] = useState("");
  const [username2, setUsername2] = useState("");

  const [searchParams] = useSearchParams();
  const sharedId = searchParams.get("shared");

  // Listen for auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (!user) setLoading(false); // Stop loading if no user
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!currentUser) return;

      const profileDocRef = doc(db, "public-profiles", currentUser.uid);
      const profileDoc = await getDoc(profileDocRef);

      if (profileDoc.exists()) {
        const data = profileDoc.data();
        setUsername(data.username || "");
        setPhotoURL(data.photoURL || "");
        setDisplayName(data.displayName || "");

        setQuizId(data.politicalQuizId);
      }
    };

    if (currentUser) fetchProfileData();
  }, [currentUser]);

  useEffect(() => {
    const getSharedCollection = async () => {
      if (!sharedId || !currentUser) {
        setLoading(false); // Stop loading if no shared ID or user
        return;
      }

      if (sharedId) {
        const sharedDocRef = doc(db, "shared-quizzes", sharedId);
        const sharedDoc = await getDoc(sharedDocRef);

        if (sharedDoc.data().senderUid === currentUser.uid) {
          setQuizId2(sharedDoc.data().receiverQuizId);

          const profile2DocRef = doc(
            db,
            "public-profiles",
            sharedDoc.data().receiverUid
          );
          const profile2Doc = await getDoc(profile2DocRef);

          if (profile2Doc.exists()) {
            const data = profile2Doc.data();
            setUsername2(data.username || "");
            setPhotoURL2(data.photoURL || "");
            setDisplayName2(data.displayName || "");
          }
        } else if (sharedDoc.data().senderUid !== currentUser.uid) {
          setQuizId2(sharedDoc.data().senderQuizId);

          const profile2DocRef = doc(
            db,
            "public-profiles",
            sharedDoc.data().senderUid
          );
          const profile2Doc = await getDoc(profile2DocRef);

          if (profile2Doc.exists()) {
            const data = profile2Doc.data();
            setUsername2(data.username || "");
            setPhotoURL2(data.photoURL || "");
            setDisplayName2(data.displayName || "");
          }
        }
      }
      setLoading(false);
    };
    getSharedCollection();
  }, [sharedId, currentUser, quizId2]);

  if (loading) {
    // Show a loading spinner or placeholder
    return (
      <Layout>
        <Container>
          <Row className="mt-10 text-align-center">
            <Col>
              <H5>Loading your results...</H5>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container>
        <Row>
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          >
            <ResultsPageBox style={{ flexDirection: "column" }}>
              <TextSm>Quiz Results</TextSm>
              <H5 className="mb-20 mt-5-real">
                What's your political alignment?
              </H5>

              {!sharedId ? (
                <>
                  {photoURL ? (
                    <ProPic src={photoURL} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName ? displayName[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                </>
              ) : (
                <div className="display-flex">
                  {photoURL ? (
                    <ProPic src={photoURL} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName ? displayName[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                  <CompareArrowsIcon className="mt-15" />
                  {photoURL2 ? (
                    <ProPic src={photoURL2} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName2 ? displayName2[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                </div>
              )}
            </ResultsPageBox>
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          >
            <BreakdownContainer>
              <BreakdownHeader background={Orange}>
                <H6 className="mb-0">
                  Score Breakdown:{" "}
                  {username ? `@${username}` : `${displayName}`}{" "}
                  {sharedId && (
                    <> {username2 ? `+ @${username2}` : `+ ${displayName2}`}</>
                  )}
                </H6>
              </BreakdownHeader>
              <BreakdownBody>
                <Axis
                  quizName="politicalAlignment"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Socially Conservative"
                  rightLabel="Socially Liberal"
                  leftDescription="Values tradition and limited social change."
                  rightDescription="Supports equality and progressive policies."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="socialLib"
                />
                <Axis
                  quizName="politicalAlignment"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Economically Conservative"
                  rightLabel="Economically Liberal"
                  leftDescription="Supports minimal regulation and free markets."
                  rightDescription="Favors progressive regulation and social welfare programs."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="econLib"
                />
                <Axis
                  quizName="politicalAlignment"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Nationalism"
                  rightLabel="Globalism"
                  leftDescription="Prioritizes national interests and limited foreign intervention."
                  rightDescription="Advocates for international cooperation and shared solutions."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="globalism"
                />
                <Axis
                  quizName="politicalAlignment"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Energy Expansion"
                  rightLabel="Environmental Conservation"
                  leftDescription="Advocates for national energy independence."
                  rightDescription="Prioritizes transition to renewable energy."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="conservation"
                />
                <Axis
                  quizName="politicalAlignment"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Private Healthcare"
                  rightLabel="Public Healthcare"
                  leftDescription="Healthcare should go through the private sector."
                  rightDescription="Access to healthcare should be a right, funded through taxes."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="public"
                />
                <Axis
                  quizName="politicalAlignment"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Personal Accountability"
                  rightLabel="Social Support"
                  leftDescription="The individual is responsible for their welfare."
                  rightDescription="Government should provide a safety net for it’s citizens."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="socialSupport"
                />
              </BreakdownBody>
            </BreakdownContainer>
          </Col>
        </Row>

        {(currentUser || !currentUser?.isAnonymous) && sharedId && (
          <Row>
            <Col
              lg={{ offset: 4, span: 4 }}
              md={{ offset: 3, span: 6 }}
              sm={{ offset: 2, span: 8 }}
              xs={{ span: 12 }}
            >
              <UpgradeContainer>
                <UpgradeHeader background={Orange}>
                  <H6 className="mb-0">Your Compatability Insights </H6>
                </UpgradeHeader>
                <UpgradeBoxLocked>
                  <BlurredBackground className="blur">
                    <H6> Core Political Ideology: </H6>
                    <TextXs>
                      {username ? `@${username}` : `${displayName}`}{" "}
                      {sharedId && (
                        <>
                          {" "}
                          {username2 ? `+ @${username2}` : `+ ${displayName2}`}
                        </>
                      )}
                    </TextXs>

                    <TextXs className="mt-20">
                      About {username ? `@${username}` : `${displayName}`}:
                      Bacon ipsum dolor amet ground round sausage kevin
                      tenderloin pork loin. Ham hock drumstick pork short ribs
                      filet mignon hamburger alcatra porchetta ribeye chuck
                      meatloaf t-bone pork chop....
                    </TextXs>
                  </BlurredBackground>
                  <BlurredOverlay>
                    <H6 color={Orange} className="mb-0">
                      Upgrade to unlock <Lock sx={{ fontSize: "14px" }} />
                    </H6>
                  </BlurredOverlay>
                </UpgradeBoxLocked>
              </UpgradeContainer>
            </Col>
          </Row>
        )}

        {(currentUser?.isAnonymous || !currentUser) && (
          <Row>
            <Col
              lg={{ offset: 4, span: 4 }}
              md={{ offset: 3, span: 6 }}
              sm={{ offset: 2, span: 8 }}
              xs={{ span: 12 }}
              className="text-align-center"
            >
              <BreakdownBottom className="justify-content-center display-column">
                <H6 className="mb-20">👇 create an account 👇</H6>
                <ButtonBrown
                  className="mt-0"
                  width="80%"
                  onClick={() =>
                    navigate("/login", { state: { linkAnonymous: true } })
                  }
                >
                  save your answers
                </ButtonBrown>
              </BreakdownBottom>
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  );
};

export default ResultsPA;
