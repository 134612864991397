import styled, { css } from "styled-components";

export const D1 = styled.h1`
  font-family: "Ranille", sans-serif;
  font-weight: 900;
  font-size: 5rem;
  font-display: swap;
  font-style: normal;
  color: ${(props) => props.color};

  @media (max-width: 950px) {
    font-size: 6rem;
  }

  @media (max-width: 600px) {
    font-size: 3.5rem;
  }

  @media (max-width: 400px) {
    font-size: 3.5rem;
  }
`;

export const D2 = styled.h1`
  font-family: "Satoshi-Variable";
  font-weight: 900;
  font-size: 3.5rem;
  font-display: swap;
  color: ${(props) => props.color};
`;

export const D3 = styled.h1`
  font-family: "Satoshi-Variable";
  font-weight: 900;
  font-size: 2.7rem;
  font-display: swap;
  color: ${(props) => props.color};
`;

export const H1 = styled.h1`
  font-family: "Ranille", sans-serif;
  font-weight: 900;
  font-display: swap;
  color: ${(props) => props.color};
`;

export const H2 = styled.h2`
  font-family: "Satoshi-Variable";
  font-weight: 900;
  font-display: swap;
  color: ${(props) => props.color};
`;

export const H3 = styled.h3`
  font-family: "Ranille", sans-serif;
  font-weight: 500;
  font-display: swap;
  margin-top: 5px;
  color: ${(props) => props.color};

  @media (max-width: 400px) {
    font-size: 1.2rem;
  }
`;

export const H4 = styled.h4`
  font-family: "Satoshi-Variable";
  font-weight: 900;
  font-display: swap;
  font-size: 20px;
  border-radius: 200px;
  color: ${(props) => props.color};
`;

export const H5 = styled.h5`
  font-family: "Ranille", sans-serif;
  font-weight: 900;
  font-display: swap;
  font-size: 15px;
  color: ${(props) => props.color};
`;

export const H5Norm = styled.h5`
  font-family: "Satoshi-Variable";
  font-weight: 900;
  font-display: swap;
  font-size: 13px;
  color: ${(props) => props.color};
`;

export const H6 = styled.h6`
  font-family: "Satoshi-Variable";
  font-weight: 900;
  font-size: 11px;
  font-display: swap;
  color: ${(props) => props.color};
`;

export const TextXs = styled.h6`
  font-family: "Helvetica Neue";
  font-weight: 500;
  font-size: 11px;
  line-height: 19px;
  font-display: swap;
  color: ${(props) => props.color};
`;

export const TextSm = styled.h6`
  font-family: "Satoshi-Medium";
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  line-height: 120%;
  font-display: swap;
  margin: 0;
  color: ${(props) => props.color};
`;

export const TextXsNorm = styled.h6`
  font-family: "Helvetica Neue";
  font-weight: 300;
  font-size: 10px;
  line-height: 19px;
  font-display: swap;
  color: ${(props) => props.color};
`;

export const Paragraph = styled.p`
  font-family: "Helvetica Neue";
  ${(props) =>
    props.footer &&
    css`
      margin-bottom: 5px;
    `}
  font-display: swap;
  font-size: 11px;
  font-weight: 300;
  color: ${(props) => props.color};
`;

export const Span = styled.span`
  font-display: swap;
  color: ${(props) => props.color};
`;
