import styled from "styled-components";
import {
  White,
  Black,
  White05,
  DarkBrown,
  DarkOrange05,
  White07,
  OffWhite,
} from "./colors";

const HomeQuizBox = styled.div`
  height: 210px;
  width: 100%;
  margin-bottom: 20px;
  background: ${(props) => props.background || DarkBrown};
  color: ${OffWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 25px;
  &:hover {
    opacity: ${(props) => (props.selected ? "1" : "0.8")};
  }
`;

export default HomeQuizBox;

export const HomeQuizBoxSm = styled.div`
  height: 160px;
  width: 100%;
  margin-bottom: 20px;
  background: ${(props) => props.background || DarkBrown};
  color: ${OffWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 20px;
  &:hover {
    opacity: ${(props) => (props.selected ? "1" : "0.8")};
  }
`;

export const HomeQuizBoxXs = styled.div`
  height: 90px;
  width: 100%;
  margin-bottom: 10px;
  background: ${(props) => props.background || DarkBrown};
  color: ${OffWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 20px;
  &:hover {
    opacity: ${(props) => (props.selected ? "1" : "0.8")};
  }
`;

export const LandingPageBox = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding: 30px 25px;
  display: flex;
  color: ${DarkBrown};
  background: ${(props) => (props.background ? props.background : White05)};
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
`;

export const ResultsPageBox = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding: 30px 25px;
  display: flex;
  color: ${DarkBrown};
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
`;

export const QuestionContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  border-radius: 0 0 5px 5px !important;
`;

export const QuestionHeader = styled.div`
  text-align: left;
  padding: 10px 15px;
  border-radius: 5px 5px 0 0;
  width: 100%;
  background: ${(props) => props.background || Black};
  color: ${White};
  min-height: 30px;
`;

export const QuestionBody = styled.div`
  padding: 10px 30px;
  width: 100%;
  background-color: ${White05};
  border-radius: 0 0 5px 5px !important;
`;

export const QuizAnswerBox = styled.div`
  min-height: 50px;
  width: 100%;
  margin-bottom: 10px;
  background: ${(props) => (props.background ? props.background : White05)};
  display: flex;
  justify-content: center;
  padding: 15px 25px;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.5s ease, background-color 0.5s ease;
  &:hover {
    opacity: ${(props) => (props.selected ? "1" : "0.8")};
  }
`;

export const BreakdownContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  border-radius: 0 0 5px 5px !important;
`;

export const BreakdownHeader = styled.div`
  text-align: left;
  padding: 10px 15px;
  border-radius: 5px 5px 0 0;
  width: 100%;
  background: ${(props) => props.background || Black};
  color: ${White};
  min-height: 30px;
`;

export const BreakdownBody = styled.div`
  min-height: 300px;
  padding: 10px 30px;
  width: 100%;
  background-color: ${White05};
  border-radius: 0 0 5px 5px !important;
`;

export const BreakdownBottom = styled.div`
  text-align: left;
  border-radius: 5px;
  width: 100%;
  min-height: 30px;
  margin-bottom: 50px;
`;

export const ResultsBox = styled.div`
  text-align: left;
  padding: 10px 20px;
  width: 100%;
  min-height: 20px;
  color: ${White};
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  display: flex;
  border-left: 5px solid ${(props) => props.background || DarkBrown};
`;

export const UpgradeContainer = styled.div`
  width: 100%;
  height: 180px;
  background-color: ${White05};
  margin-bottom: 40px;
  border: 1px dashed ${DarkOrange05};
  border-radius: 5px !important;
`;

export const UpgradeHeader = styled.div`
  text-align: left;
  padding: 10px 15px;
  border-radius: 5px 5px 0 0;
  width: 100%;
  background: ${(props) => props.background || Black};
  color: ${White};
  min-height: 30px;
`;

export const UpgradeBoxLocked = styled.div`
  width: 100%;
  border-radius: 5px !important;
  position: relative;
`;

export const BlurredBackground = styled.div`
  position: absolute;
  padding: 20px 30px;
  height: 130px;
  filter: blur(2.2px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const BlurredOverlay = styled.div`
  position: absolute;
  top: 50px;
  left: 30%;
  color: black;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 15px;
  background-color: ${White07};
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  cursor: pointer;
  z-index: 1; /* Ensure it's on top of the blurred content */

  &:hover {
    background-color: ${White05};
  }
`;
