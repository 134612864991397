import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import QuizIcon from "@mui/icons-material/Quiz";

import { Brown, DarkBrown, White } from "../theme/colors";
import { Notifications } from "@mui/icons-material";

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  color: ${Brown} !important; // Default color for icon;
  &.Mui-selected {
    color: ${DarkBrown} !important; // Color when selected
  }
`;

export default function LabelBottomNavigation() {
  const location = useLocation(); // Get current path
  const navigate = useNavigate(); // Navigation function

  const [value, setValue] = React.useState(location.pathname);

  // Sync state with current route
  React.useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue); // Navigate to the selected path
  };

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={handleChange}
      style={{ background: White }}
    >
      <StyledBottomNavigationAction
        value="/home"
        icon={<QuizIcon style={{ fontSize: "25px" }} />}
      />
      <StyledBottomNavigationAction
        value="/results"
        icon={<Notifications style={{ fontSize: "25px" }} />}
      />
      <StyledBottomNavigationAction
        value="/profile"
        icon={<AccountCircleIcon style={{ fontSize: "25px" }} />}
      />
    </BottomNavigation>
  );
}
