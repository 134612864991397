import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";

import styled from "styled-components";
import { Black, DarkBrown, OffWhite } from "./colors";
import { TextXsNorm } from "./text/Headings";

import { auth, db } from "../../services/firebase.config";

// Styled components
const XAxisContainer = styled.div`
  width: 100%;
  margin: 20px auto;
  margin-bottom: 30px;
  text-align: center;
  font-family: Arial, sans-serif;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
`;

const Label = styled.span`
  font-weight: bold;
  font-size: 11px;
`;

const XAxisLine = styled.div`
  position: relative;
  height: 2px;
  background-color: ${Black};
  border-radius: 2px;
  margin: 10px 0;
`;

const Indicator = styled.div`
  position: absolute;
  top: -12px;
  transform: translateX(-50%);
  left: ${(props) => props.position};
`;

const ProfilePicture = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid #333;
`;

const SecPic = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50px;
  border: 3px solid ${DarkBrown};
  background-color: ${DarkBrown};
  margin-top: 3px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${OffWhite};
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  font-size: 10px;
  color: #666;
`;

const Description = styled.span`
  width: 30%;
  text-align: ${(props) => props.textAlign};
`;

const Midline = styled.div`
  position: absolute;
  top: -11px;
  left: 50%;
  transform: translateX(-50%);
  height: 25px;
  width: 1px;
  background-color: #333;
`;

// React component
const Axis = ({
  quizName,
  quizId,
  senderQuizId,
  leftLabel,
  rightLabel,
  leftDescription,
  rightDescription,
  displayName,
  profilePicture,
  profilePicture2,
  displayName2,
  field,
  onPercentageUpdate,
}) => {
  const currentUser = auth.currentUser;

  const [position, setPosition] = useState("50%");
  const [position2, setPosition2] = useState("");
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    const fetchQuizResults = async () => {
      if (!currentUser || !quizId || !quizName) return;

      const docRef = doc(db, "quiz-answers", quizId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const matchingAnswers = Object.values(data.answers).filter(
          (item) => item.field === field
        );

        if (matchingAnswers.length > 0) {
          const totalPercentage = matchingAnswers.reduce(
            (acc, answer) => acc + answer.percentage,
            0
          );

          const averagePercentage = totalPercentage / matchingAnswers.length;
          setPosition(`${averagePercentage}%`);

          if (onPercentageUpdate) {
            onPercentageUpdate(field, averagePercentage, parseFloat(position2));
          }
        } else {
          console.log("No matching answers found for field:", field);
        }
      } else {
        console.log("No document found for user");
      }
    };

    fetchQuizResults();
    // eslint-disable-next-line
  }, [currentUser, field, quizId, quizName, onPercentageUpdate]);

  useEffect(() => {
    const fetchSecondQuizResults = async () => {
      if (!senderQuizId || !quizId || !quizName) return;

      const docRef = doc(db, "quiz-answers", senderQuizId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const matchingAnswers = Object.values(data.answers).filter(
          (item) => item.field === field
        );

        if (matchingAnswers.length > 0) {
          const totalPercentage = matchingAnswers.reduce(
            (acc, answer) => acc + answer.percentage,
            0
          );
          const averagePercentage = totalPercentage / matchingAnswers.length;
          setPosition2(`${averagePercentage}%`);

          // Update the parent
          if (onPercentageUpdate) {
            onPercentageUpdate(field, parseFloat(position), averagePercentage);
          }
        } else {
          console.log("No matching answers found for field:", field);
        }
      } else {
        console.log("No document found for user");
      }
    };

    fetchSecondQuizResults();
    setLoading(false);
    // eslint-disable-next-line
  }, [senderQuizId, field, quizId, quizName, onPercentageUpdate]);

  return (
    <>
      {!loading && (
        <XAxisContainer>
          <LabelContainer>
            <Label>{leftLabel}</Label>
            <Label>{rightLabel}</Label>
          </LabelContainer>
          <XAxisLine>
            <Midline />
            <Indicator position={position}>
              {profilePicture ? (
                <ProfilePicture src={profilePicture} />
              ) : (
                <SecPic>
                  <TextXsNorm className="mb-0">
                    {displayName ? displayName[0] : "?"}
                  </TextXsNorm>
                </SecPic>
              )}
            </Indicator>
            {position2 && (
              <Indicator position={position2}>
                {profilePicture2 ? (
                  <ProfilePicture src={profilePicture2} />
                ) : (
                  <SecPic>
                    <TextXsNorm className="mb-0">
                      {displayName2 ? displayName2[0] : "?"}
                    </TextXsNorm>
                  </SecPic>
                )}
              </Indicator>
            )}
          </XAxisLine>
          <DescriptionContainer>
            <Description textAlign={"left"}>{leftDescription}</Description>
            <Description textAlign={"right"}>{rightDescription}</Description>
          </DescriptionContainer>
        </XAxisContainer>
      )}
    </>
  );
};

export default Axis;
