import questionsData from "./QuestionData";
import QuestionTemplate from "../../templates/FDQuestionTemplate";

const Question19FD = () => {
  const questionData = questionsData.find((q) => q.id === "19");

  return (
    <QuestionTemplate
      question={questionData}
      answers={questionData.answers}
      field={questionData.field}
    />
  );
};

export default Question19FD;
