import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

import { doc, getDoc } from "firebase/firestore";

import Layout from "../components/layout/layout";
import {
  Brown,
  Burgundy,
  Burgundy03,
  DarkBrown,
  DarkBrown09,
  DarkOrange,
  DarkOrange03,
  Green03,
  OffWhite,
  Orange,
  Orange03,
} from "../components/theme/colors";
import { H1, H5, H6, TextXs } from "../components/theme/text/Headings";
import { ButtonOutlineBrown } from "../components/theme/Button";

import { db } from "../services/firebase.config";
import { useAuth } from "../services/AuthContext";
import { useLoading } from "../services/LoadingContext";
import HomeQuizBox, { HomeQuizBoxXs } from "../components/theme/QuizBox";
import { Book, Lock, PersonPin, PsychologyAlt } from "@mui/icons-material";

const ProPic = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 50px;
  border: 3px solid ${DarkBrown};
`;

const SecPic = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 50px;
  border: 3px dotted ${DarkBrown};
  margin-top: 3px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${DarkBrown};
`;

const InfoBox = styled.div`
  border-radius: 10px;
  padding: 10px 0;
  margin: 5px 0 10px 0;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${DarkBrown};
`;

const Line = styled.div`
  background-color: ${DarkBrown09};
  height: 2px;
  border-radius: 5px;
  margin: 20px 0 0;
  width: 80%;
`;

const Plus = styled.div`
  background-color: ${Brown};
  border-radius: 5px;
  color: ${OffWhite};
  padding: 5px 10px;
`;

const Profile = () => {
  const { currentUserState } = useAuth();
  const { setLoading } = useLoading(); // Access global loading context

  const [profileLoaded, setProfileLoaded] = useState(false); // Tracks local data load

  const [username, setUsername] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [bio, setBio] = useState("");

  // const [fDCompleted, setFDCompleted] = useState(false);
  const [pACompleted, setPACompleted] = useState(false);
  const [mBCompleted, setMBCompleted] = useState(false);
  const [ssCompleted, setSSCompleted] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!currentUserState) return;

      setLoading(true); // Start spinner globally
      try {
        const profileDocRef = doc(db, "public-profiles", currentUserState.uid);
        const profileDoc = await getDoc(profileDocRef);

        if (profileDoc.exists()) {
          const data = profileDoc.data();
          setUsername(data.username || "");
          setPhotoURL(data.photoURL || "");
          setDisplayName(data.displayName || "");
          setBio(data.bio || "");

          // const fdQuizId = profileDoc.data().firstDateQuizId;
          const paQuizId = profileDoc.data().politicalQuizId;
          const mbQuizId = profileDoc.data().myersBriggsQuizId;
          const ssQuizId = profileDoc.data().sexStylesQuizId;

          // if (fdQuizId) {
          //   const docRef = doc(db, "quiz-answers", fdQuizId);
          //   const docSnap = await getDoc(docRef);
          //   if (docSnap.exists() && docSnap.data().status === "completed") {
          //     setFDCompleted(true);
          //   }
          // }

          if (paQuizId) {
            const docRef = doc(db, "quiz-answers", paQuizId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists() && docSnap.data().status === "completed") {
              setPACompleted(true);
            }
          }

          if (mbQuizId) {
            const docRef = doc(db, "quiz-answers", mbQuizId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists() && docSnap.data().status === "completed") {
              setMBCompleted(true);
            }
          }

          if (ssQuizId) {
            const docRef = doc(db, "quiz-answers", ssQuizId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists() && docSnap.data().status === "completed") {
              setSSCompleted(true);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setProfileLoaded(true); // Mark local data load as complete
        setLoading(false); // Stop spinner globally
      }
    };

    fetchProfileData();
  }, [currentUserState, setLoading]);

  if (!profileLoaded) {
    // Return nothing to ensure the spinner persists
    return null;
  }

  return (
    <Layout>
      {currentUserState !== null && (
        <Container>
          <Row className="mt-20">
            <Col
              lg={{ offset: 4, span: 4 }}
              md={{ offset: 3, span: 6 }}
              sm={{ offset: 2, span: 8 }}
              xs={{ offset: 1, span: 10 }}
              className="text-align-center"
            >
              <H5 className="mt-0 mb-10" color={DarkBrown}>
                {username ? `@${username}` : "no username"}
              </H5>
              <InfoBox>
                {photoURL ? (
                  <ProPic src={photoURL} />
                ) : (
                  <SecPic>
                    <H1 className="mb-0">
                      {displayName ? displayName[0] : "?"}
                    </H1>
                  </SecPic>
                )}
                <H5 className="mt-20 mb-0">
                  {displayName ? displayName : "Your Name"}
                </H5>

                <TextXs className="mb-0">
                  {bio ? bio : "Please add a bio"}
                </TextXs>
                <Line />
              </InfoBox>
              <div className="display-flex">
                <HomeQuizBox background={DarkBrown}>
                  <PersonPin className="mb-20" />
                  <H5>The Mirror</H5>
                  <TextXs>
                    A guide to your <br /> inner workings.
                  </TextXs>
                  <Plus className="mt-10">
                    <TextXs className="mb-0">
                      Upgrade <Lock sx={{ fontSize: "11px" }} />
                    </TextXs>
                  </Plus>
                </HomeQuizBox>

                <HomeQuizBox background={Brown}>
                  <Book className="mb-20" />
                  <H5>Digest</H5>
                  <TextXs>Overview of your core personality.</TextXs>
                </HomeQuizBox>
              </div>

              <H5 className="text-align-left mt-20 mb-10" color={DarkBrown}>
                Core Check Results
              </H5>

              <div className="display-flex">
                {mBCompleted ? (
                  <Link
                    to="/myers-briggs/results"
                    style={{
                      display: "block",
                      width: "100%",
                      textDecoration: "none",
                    }}
                  >
                    <HomeQuizBoxXs background={DarkOrange}>
                      <PsychologyAlt className="mb-10" />
                      <H6 className="mb-0">Myers Briggs</H6>
                    </HomeQuizBoxXs>
                  </Link>
                ) : (
                  <Link
                    to=""
                    style={{
                      display: "block",
                      width: "100%",
                      textDecoration: "none",
                    }}
                  >
                    <HomeQuizBoxXs background={DarkOrange03}>
                      <Lock className="mb-10" />
                      <H6 className="mb-0">Myers Briggs</H6>
                    </HomeQuizBoxXs>
                  </Link>
                )}

                <Link
                  to=""
                  style={{
                    display: "block",
                    width: "100%",
                    textDecoration: "none",
                  }}
                >
                  <HomeQuizBoxXs background={Green03}>
                    <Lock className="mb-10" />
                    <H6 className="mb-0">Working Styles</H6>
                  </HomeQuizBoxXs>
                </Link>
              </div>

              <div className="display-flex">
                {pACompleted ? (
                  <Link
                    to="/political-alignment/results"
                    style={{
                      display: "block",
                      width: "100%",
                      textDecoration: "none",
                    }}
                  >
                    <HomeQuizBoxXs background={Orange}>
                      <PsychologyAlt className="mb-10" />
                      <H6 className="mb-0">Political Alignment</H6>
                    </HomeQuizBoxXs>
                  </Link>
                ) : (
                  <Link
                    to=""
                    style={{
                      display: "block",
                      width: "100%",
                      textDecoration: "none",
                    }}
                  >
                    <HomeQuizBoxXs background={Orange03}>
                      <Lock className="mb-10" />
                      <H6 className="mb-0">Political Alignment</H6>
                    </HomeQuizBoxXs>
                  </Link>
                )}
                {ssCompleted ? (
                  <Link
                    to="/sex-styles/results"
                    style={{
                      display: "block",
                      width: "100%",
                      textDecoration: "none",
                    }}
                  >
                    <HomeQuizBoxXs background={Burgundy}>
                      <PsychologyAlt className="mb-10" />
                      <H6 className="mb-0">Sex Styles</H6>
                    </HomeQuizBoxXs>
                  </Link>
                ) : (
                  <Link
                    to=""
                    style={{
                      display: "block",
                      width: "100%",
                      textDecoration: "none",
                    }}
                  >
                    <HomeQuizBoxXs background={Burgundy03}>
                      <Lock className="mb-10" />
                      <H6 className="mb-0">Sex Styles</H6>
                    </HomeQuizBoxXs>
                  </Link>
                )}
              </div>

              <Col className="mt-10">
                <Link to="/settings">
                  <ButtonOutlineBrown className="mt-20" width="100%">
                    Edit Profile
                  </ButtonOutlineBrown>
                </Link>
              </Col>
            </Col>
          </Row>
        </Container>
      )}
    </Layout>
  );
};

export default Profile;
