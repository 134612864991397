// questionsData.js
const questionsData = [
  {
    id: "1",
    nextQuestionId: "2",
    field: "socialLib",
    questionText:
      "Should marriage rights be the same for both same-sex and heterosexual couples?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "Same-sex unions should be allowed, with legal distinctions from marriage.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "Marriage should only be between a man and a woman.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "Same-sex marriage should have the same legal rights as heterosexual marriage with no exemptions.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "Same-sex marriage should have equal rights, but religious exemptions should be respected.",
      },
    ],
  },
  {
    id: "2",
    nextQuestionId: "3",
    field: "econLib",
    questionText:
      "Do you believe the minimum wage should be raised to ensure a livable income?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "Minimum wage should be raised to help low-income workers, but with some regional adjustments.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "Minimum wage should remain low to encourage growth and keep costs down for businesses.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "Minimum wage should be determined by the free market, not the government.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "Minimum wage should be increased to a livable wage nationwide to ensure all workers meet basic needs.",
      },
    ],
  },
  {
    id: "3",
    nextQuestionId: "4",
    field: "socialSupport",
    questionText:
      "Should the government provide financial assistance programs for low-income individuals and families?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "The government has a responsibility to provide financial assistance to all low-income individuals and families.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "The government should provide minimal assistance, with most support coming from community resources.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "Financial support should come primarily from private charities, not the government.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "The government should offer financial assistance programs to help lift people out of poverty.",
      },
    ],
  },
  {
    id: "4",
    nextQuestionId: "5",
    field: "conservation",
    questionText:
      "Should the U.S. phase out fracking as part of a transition to renewable energy?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Fracking should continue as it supports energy independence.",
      },
      {
        answer: "2",
        percentage: 66,
        copy: "Fracking should be gradually reduced to encourage the shift toward renewable energy sources.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "Fracking should be permitted with limited regulation to ensure environmental safety.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "The U.S. should phase out fracking entirely to focus on sustainable and renewable energy.",
      },
    ],
  },
  {
    id: "5",
    nextQuestionId: "6",
    field: "globalism",
    questionText:
      "Do you support U.S. financial and military aid to Ukraine in its conflict with Russia?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "The U.S. should support Ukraine with financial and military aid to uphold international stability.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "The U.S. has a duty to fully support Ukraine in defense of democracy and human rights globally.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "The U.S. should not involve itself in conflicts that do not directly threaten national security.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "The U.S. should provide only limited support to Ukraine to avoid escalating tensions.",
      },
    ],
  },
  {
    id: "6",
    nextQuestionId: "7",
    field: "socialLib",
    questionText:
      "Should background checks be required for all gun sales, including private sales?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "Background checks should be required for most sales, with exceptions for family transfers.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "Background checks should only be required for licensed gun dealers, not private sales.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "Background checks should be required for all gun sales, with additional restrictions on high-risk buyers.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "Background checks should be required for all gun sales, including private transactions.",
      },
    ],
  },
  {
    id: "7",
    nextQuestionId: "8",
    field: "econLib",
    questionText:
      "Should employees have more legal rights to unionize and negotiate for better pay and benefits?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "Employees should have full rights to unionize without restrictions for better pay and benefits.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "Unions should be permitted, but with restrictions to prevent disruption of business operations.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "Unions should have minimal influence; employees should negotiate individually with employers.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "Employees should have more legal rights to unionize and negotiate with their employers.",
      },
    ],
  },
  {
    id: "8",
    nextQuestionId: "9",
    field: "socialLib",
    questionText:
      "Should marijuana be legalized for recreational use across the country?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "Marijuana should be legalized for recreational use, with some restrictions on age and purchase limits.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "Marijuana should be decriminalized but not legalized for recreational use.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "Marijuana should be fully legalized and regulated similarly to alcohol.",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "Marijuana should remain illegal for recreational use in all states.",
      },
    ],
  },
  {
    id: "9",
    nextQuestionId: "10",
    field: "socialSupport",
    questionText:
      "Should the government provide more services to address homelessness, or should communities and charities take the lead?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "The government should take full responsibility for addressing homelessness.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "The government should only intervene when communities and charities are unable to provide support.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "The government should provide services to address homelessness in partnership with local groups.",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "Addressing homelessness should be the responsibility of communities and charities, not the government.",
      },
    ],
  },
  {
    id: "10",
    nextQuestionId: "11",
    field: "public",
    questionText:
      "Should the government cap the cost of prescription drugs for patients?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "The government should not interfere in drug pricing; the market should set the cost.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "The government should only cap drug prices for life-saving medications.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "The government should strictly cap drug prices to ensure all medications are accessible.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "The government should regulate drug prices to make essential medications affordable.",
      },
    ],
  },
  {
    id: "11",
    nextQuestionId: "12",
    field: "socialLib",
    questionText:
      "Do you support unrestricted access to abortion services for women?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Abortion should be illegal in all or most cases.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "Abortion should be fully legal and accessible without restrictions.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "Abortion should be allowed with some restrictions, like waiting periods or parental consent for minors.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "Abortion should be legal only in cases of rape, incest, or risk to the mother’s life.",
      },
    ],
  },
  {
    id: "12",
    nextQuestionId: "13",
    field: "econLib",
    questionText:
      "Do you support raising taxes on corporations to increase government revenue?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "Corporate taxes should stay the same to maintain a stable business environment.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "Corporate taxes should be reduced to encourage business growth and job creation.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "Corporate taxes should be significantly increased to provide more funding for social programs and reduce income inequality.",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "Corporate taxes should be raised modestly to help fund public services and infrastructure.",
      },
    ],
  },
  {
    id: "13",
    nextQuestionId: "14",
    field: "socialSupport",
    questionText:
      "Should retirement planning be primarily an individual responsibility, or should the government play a major role?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "The government should ensure a secure retirement for everyone as a guaranteed benefit.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "The government should provide basic retirement options, but individuals should mostly plan on their own.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "The government should expand programs to help individuals save for retirement, with a shared responsibility.",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "Individuals should be fully responsible for their own retirement planning and savings.",
      },
    ],
  },
  {
    id: "14",
    nextQuestionId: "15",
    field: "globalism",
    questionText:
      "Should the U.S. increase foreign aid to developing countries to address global poverty?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "The U.S. should provide foreign aid to developing countries to promote stability and goodwill.",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "Foreign aid should be limited and given only when it directly benefits U.S. interests.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "The U.S. should focus its resources on domestic issues, not foreign aid.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "The U.S. has a responsibility to increase foreign aid to address global poverty and support humanitarian efforts.",
      },
    ],
  },
  {
    id: "15",
    nextQuestionId: "16",
    field: "conservation",
    questionText:
      "Should the U.S. create stronger climate action agreements with other countries?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "The U.S. should avoid international climate agreements that limit economic flexibility.",
      },
      {
        answer: "2",
        percentage: 66,
        copy: "The U.S. should work closely with other countries to address climate change through balanced agreements.",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "The U.S. should participate in climate agreements only if they don’t place a burden on our economy.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "The U.S. should lead in forming strong climate action agreements to tackle the global climate crisis.",
      },
    ],
  },
  {
    id: "16",
    nextQuestionId: "17",
    field: "globalism",
    questionText:
      "Is it important for the U.S. to maintain strong alliances with organizations like the UN and NATO",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "The U.S. should reduce its involvement in international organizations and prioritize national sovereignty.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "The U.S. should strengthen its commitment to international organizations to promote global cooperation.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "The U.S. should maintain strong alliances with the UN, NATO, and others to support global security.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "The U.S. should remain in international organizations but limit its contributions and influence.",
      },
    ],
  },
  {
    id: "17",
    nextQuestionId: "18",
    field: "socialLib",
    questionText:
      "Should social media platforms be responsible for removing hate speech and misinformation?",
    answers: [
      {
        answer: "1",
        percentage: 66,
        copy: "Social media companies should remove hate speech and misinformation, but with clear oversight.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "Social media companies should proactively remove hate speech and misinformation to create a safer online environment.",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "Social media companies should not regulate content; users should have full freedom of speech.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "Social media companies should only remove illegal content, like threats of violence.",
      },
    ],
  },
  {
    id: "18",
    field: "public",
    questionText:
      "Do you believe the government should provide free universal healthcare.",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Healthcare should be private; government involvement leads to inefficiency.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "Yes, healthcare should be a human right.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "The government should offer universal healthcare with options for private care.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "The government should only provide healthcare for those who can't afford it, not everyone.",
      },
    ],
  },
];

export default questionsData;
