import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { doc, getDoc, setDoc } from "firebase/firestore";

import { Col, Container, Row } from "react-bootstrap";
import { TextField } from "@mui/material";

import { signOut } from "firebase/auth";

import Layout from "../components/layout/layout";
import { H5 } from "../components/theme/text/Headings";
import { ButtonBrown, ButtonOutlineBrown } from "../components/theme/Button";
import { DarkBrown } from "../components/theme/colors";

import { useAuth } from "../services/AuthContext";
import { auth, db } from "../services/firebase.config";

const Settings = () => {
  const navigate = useNavigate();
  const { currentUserState } = useAuth();

  const [username, setUsername] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [bio, setBio] = useState("");

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!currentUserState) return;

      const profileDocRef = doc(db, "public-profiles", currentUserState.uid);
      const profileDoc = await getDoc(profileDocRef);

      if (profileDoc.exists()) {
        const data = profileDoc.data();
        setUsername(data.username || "");
        setPhotoURL(data.photoURL || "");
        setDisplayName(data.displayName || "");
        setBio(data.bio || "");
      }
    };

    fetchProfileData();
  }, [currentUserState]);

  const handleUpdateProfile = async () => {
    if (!currentUserState) {
      alert("You need to be logged in to update your profile");
      return;
    }

    const profileDocRef = doc(db, "public-profiles", currentUserState.uid);

    try {
      await setDoc(
        profileDocRef,
        {
          uid: currentUserState.uid,
          username: username,
          photoURL: photoURL,
          displayName: displayName,
          bio: bio,
        },
        { merge: true } // Merge to add to the same document
      );

      navigate("/profile");
    } catch (error) {
      console.error("Error adding answer: ", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.log("sign out error", error);
    }
  };

  return (
    <Layout>
      <Container>
        <Row className="mt-30 justify-content-center">
          <Col className="mt-30" style={{ padding: "0px 50px" }}>
            <H5 className="text-align-left mt-10" color={DarkBrown}>
              Account Settings
            </H5>
            <TextField
              className="mt-30"
              fullWidth
              label="username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{
                "& .MuiInputBase-input": { fontSize: "13px" }, // Input text font size
                "& .MuiInputLabel-root": { fontSize: "13px" }, // Label font size
              }}
            />

            <TextField
              fullWidth
              className="mt-30"
              label="Profile picture URL"
              variant="outlined"
              value={photoURL}
              onChange={(e) => setPhotoURL(e.target.value)}
              sx={{
                "& .MuiInputBase-input": { fontSize: "13px" }, // Input text font size
                "& .MuiInputLabel-root": { fontSize: "13px" }, // Label font size
              }}
            />

            <TextField
              fullWidth
              className="mt-30"
              label="display name"
              variant="outlined"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              sx={{
                "& .MuiInputBase-input": { fontSize: "13px" }, // Input text font size
                "& .MuiInputLabel-root": { fontSize: "13px" }, // Label font size
              }}
            />

            <TextField
              fullWidth
              className="mt-30"
              label="bio"
              variant="outlined"
              multiline
              rows={2}
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              sx={{
                "& .MuiInputBase-input": { fontSize: "13px" }, // Input text font size
                "& .MuiInputLabel-root": { fontSize: "13px" }, // Label font size
              }}
            />

            <ButtonBrown
              className="mt-50"
              width="100%"
              onClick={handleUpdateProfile}
            >
              Save Changes
            </ButtonBrown>

            <ButtonOutlineBrown
              width="100%"
              className="mt-50"
              onClick={handleSignOut}
            >
              Sign Out
            </ButtonOutlineBrown>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Settings;
