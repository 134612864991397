export const OffWhite = "#EBE1D8";
export const Green = "#0A402D";
export const DarkBrown = "#5e2921";
export const Brown = "#7B352B";
export const DarkOrange = "#EB6132";
export const Orange = "#E88E38";
export const DarkYellow = "#EBB436";
export const Cream = "#ECCB88";
export const Burgundy = "#501C2C";
export const Carmine = "#D7003F";

export const DarkBrown005 = "rgb(93,41,33, 0.05)";
export const DarkBrown01 = "rgb(93,41,33, 0.1)";
export const DarkBrown03 = "rgb(93,41,33, 0.3)";
export const DarkBrown05 = "rgb(93,41,33, 0.5)";
export const DarkBrown07 = "rgb(93,41,33, 0.7)";
export const DarkBrown09 = "rgb(93,41,33, 0.9)";

export const DarkOrange01 = "rgb(234,97,49, 0.1)";
export const DarkOrange03 = "rgb(234,97,49, 0.3)";
export const DarkOrange05 = "rgb(234,97,49, 0.5)";
export const DarkOrange07 = "rgb(234,97,49, 0.7)";
export const DarkOrange09 = "rgb(234,97,49, 0.9)";

export const Green03 = "rgb(10,64,45,0.3)";
export const Orange03 = "rgb(231,142,55,0.3)";
export const Burgundy03 = "rgb(80,28,44,0.3)";

export const Black005 = "rgba(0, 0, 0, 0.05)";
export const Black09 = "rgba(0, 0, 0, 0.9)";
export const Black07 = "rgba(0, 0, 0, 0.7)";
export const Black05 = "rgba(0, 0, 0, 0.5)";
export const Black03 = "rgba(0, 0, 0, 0.3)";
export const Black01 = "rgba(0, 0, 0, 0.1)";

export const White01 = "rgba(255, 255, 255, 0.1)";
export const White03 = "rgba(255, 255, 255, 0.3)";
export const White05 = "rgba(255, 255, 255, 0.5)";
export const White07 = "rgba(255, 255, 255, 0.7)";
export const White08 = "rgba(255, 255, 255, 0.8)";

export const White = "#fff";
export const Black = "#000";
