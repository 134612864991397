import { Container, Row, Col, Navbar, NavbarBrand, Nav } from "react-bootstrap";
import { D1, H4, H5 } from "../components/theme/text/Headings";

import "bootstrap/dist/css/bootstrap.min.css";
import {
  Cream,
  DarkBrown,
  DarkOrange,
  DarkYellow,
  OffWhite,
  Orange,
  White,
} from "../components/theme/colors";
import { ButtonBrown } from "../components/theme/Button";
import { Link } from "react-router-dom";

import logoImg from "../images/logo/vBrown.png";
import iphoneMock from "../images/iphone-mocks/2.png";
import styled from "styled-components";

const backgroundStyle = {
  background: OffWhite, // Example background
  height: "93vh",
  position: "relative",
  overflow: "hidden",
};

const textStyle = {
  padding: "20px",
  borderRadius: "10px",
  color: White,
};

const BackgroundLines = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  left: 25%;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Line = styled.div`
  height: 100vh;
  width: 10px;
  margin-right: 2px;
  background-color: ${(props) => props.color};
`;

const Landing = () => {
  return (
    <div style={{ overflowY: "hidden", backgroundColor: OffWhite }}>
      <Navbar
        expand="lg"
        style={{
          border: "none",
          padding: "0 0 10px",
        }}
      >
        <BackgroundLines>
          <Line color={DarkOrange} />
          <Line color={Orange} />
          <Line color={DarkYellow} />
          <Line color={Cream} />
        </BackgroundLines>
        <Container>
          <NavbarBrand style={{ zIndex: 2 }}>
            <img
              src={logoImg}
              alt="Vibe Check Logo"
              style={{ height: "30px", marginTop: "10px" }}
            />
          </NavbarBrand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="custom-toggler mt-10"
            style={{ zIndex: 2 }}
          />
          <Navbar.Collapse
            className="justify-content-end"
            style={{ zIndex: 2 }}
          >
            <Nav className="justify-content-end text-align-right mt-20">
              <Nav.Link
                href="mailto: mehak@clickbaitcreative.com"
                style={{ color: DarkBrown }}
              >
                <H5>Contact Us</H5>
              </Nav.Link>
              <Nav.Link href="#link" style={{ color: DarkBrown }}>
                <H5>Privacy Policy</H5>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Col
        lg={{ offset: 4, span: 4 }}
        md={{ offset: 3, span: 6 }}
        sm={{ offset: 2, span: 8 }}
        xs={{ span: 12 }}
      >
        <div style={backgroundStyle}>
          <Container style={{ position: "relative" }}>
            {/* Header Section */}
            <Row
              style={{ paddingTop: "1vh", position: "relative", zIndex: 10 }}
            >
              <Col style={{ textAlign: "center" }}>
                <div style={textStyle}>
                  <D1 color={DarkBrown}>
                    Get a <br />
                    Vibe Check
                  </D1>
                  <H4 className="mt-10" color={DarkBrown}>
                    Send a link. Discover who they really are.
                  </H4>
                  <div className="display-flex mt-40 mb-20 display-column">
                    <Link to="/login">
                      <ButtonBrown width="100%">Take a Quiz</ButtonBrown>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <img
                src={iphoneMock}
                style={{ width: "45%", minWidth: "485px", zIndex: 2 }}
                alt="iphone mock"
              />
            </Row>
          </Container>
        </div>
      </Col>
    </div>
  );
};

export default Landing;
